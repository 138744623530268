import * as React from 'react';
import { matchPath, useLocation } from 'react-router';
import {
  Box,
  Chip,
  Divider,
  List,
  ListSubheader,
  styled,
  Toolbar,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import {
  useAIDataDesignerMVP,
  useConsoleGA,
  useHidePlayground,
} from 'common/featureFlags';
import LoaderFull from 'common/Loader/Full';
import { useSidebarStatus } from 'common/useSidebarStatus';
import { USER_INVITES_POLLING_INTERVAL } from 'constants/pollingIntervals';
import {
  Activity,
  Assistant,
  Blueprint,
  Builder,
  Connection,
  CreditCard,
  Dashboard,
  GPT,
  Key,
  Playground,
  Project,
  Settings,
  UsageReport,
  User,
  Workflow,
} from 'icons/figma';
import { useLoadInvitesQuery } from 'src/api';
import { useZeroProjectsView } from 'src/Console/Projects/util';
import {
  ACTIVITY_ROUTE,
  BLUEPRINTS_ROUTE,
  BUILDER_LANDING_PAGE,
  CHAT_ROUTE,
  CONNECTIONS_ROUTE,
  DASHBOARD_ROUTE,
  PLAYGROUND_ROUTE,
  PROJECTS_LIST_ROUTE,
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT,
  USER_API_KEY_ROUTE,
  USER_BILLING_ROUTE,
  USER_INVITES_ROUTE,
  USER_ROUTE,
  USER_USAGE_ROUTE,
  WORKFLOWS_ROUTE,
} from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';
import AppSidebarLayout from '../Layout/Sidebar';
import { PrimaryLinkItem } from './sidebarLinkItems';
import { ToggleButton } from './ToggleButton';

const StyledDivider = styled(Divider)(({ theme }) => ({
  color: theme.palette.primary[100],
  margin: 0,
}));

const StyledListSubHeader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.body2Strong,
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 4, 2, 3),
}));

const AppSidebar = () => {
  const { isSidebarOpen } = useSidebarStatus();
  const inviteCount =
    useLoadInvitesQuery(undefined, {
      pollingInterval: USER_INVITES_POLLING_INTERVAL,
    }).data?.filter(({ expired }) => !expired)?.length || 0;
  const { compileLocation } = useConsoleRoute();
  const ids = [uuid(), uuid(), uuid()];

  const { value: consoleGaNavigation, loading: flagLoading } = useConsoleGA();

  const { noProjectsView, loading } = useZeroProjectsView();

  const { value: hidePlayground, loading: isLoadingFeatureFlags } =
    useHidePlayground();

  const { value: AIDataDesignerEnabled, loading: isLoadingAIDDFlag } =
    useAIDataDesignerMVP();

  const showPlaygroundLink = !isLoadingFeatureFlags && !hidePlayground;
  const showAIDataDesigner = !isLoadingAIDDFlag && AIDataDesignerEnabled;

  const { pathname } = useLocation();

  /**
   * Check if the destination of the link is currently in the path.
   * If pathname is a child route of the pathname then show the parent as selected.
   *
   * example: project model page '/:project_id/models' would have the '/projects' link
   * be set as selected.
   * @param destPathname - string of the Route.pathname
   * @param isExact - boolean to enable exact matching. Useful for /user/me routes
   *
   * @returns boolean - true if given pathname is determined selected, otherwise false.
   */
  const checkSelected = React.useCallback(
    (destPathname: string, isExact = false): boolean => {
      /** When checking for project, check also for project child paths */
      if (destPathname === PROJECTS_LIST_ROUTE.path) {
        // If on projects list return true.
        if (pathname === PROJECTS_LIST_ROUTE.path) {
          return true;
        }
        // Create string array of project pathnames, exclude redirects.
        const projectRoutes = SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT.map(
          ROUTE => 'path' in ROUTE && ROUTE?.path
        ).filter(path => typeof path === 'string') as string[];
        // If matchPath fails, null is returned so using !! we can ensure truthy/falsy.
        return !!matchPath(pathname, projectRoutes);
      }
      return !!matchPath(pathname, { path: destPathname, exact: isExact });
    },
    [pathname]
  );

  if (loading || flagLoading) {
    return <LoaderFull />;
  }

  if (consoleGaNavigation) {
    return (
      <AppSidebarLayout>
        <Toolbar variant="dense" sx={{ mx: -6, boxShadow: 'none' }} />
        <ToggleButton />
        <Box
          sx={{ flex: 1, px: 4, overflowY: isSidebarOpen ? 'auto' : 'hidden' }}
        >
          <List sx={{ py: 4 }}>
            <PrimaryLinkItem
              name="Dashboard"
              id="Dashboard"
              to={compileLocation(DASHBOARD_ROUTE)}
              icon={<Dashboard width={16} height={16} />}
              disabled={!isSidebarOpen}
              selected={checkSelected(DASHBOARD_ROUTE.path)}
            />
            <PrimaryLinkItem
              name="Blueprints"
              id="Blueprints"
              to={compileLocation(BLUEPRINTS_ROUTE)}
              icon={<Blueprint width={16} height={16} />}
              disabled={!isSidebarOpen}
              selected={checkSelected(BLUEPRINTS_ROUTE.path)}
            />
          </List>

          {(showPlaygroundLink || showAIDataDesigner) && (
            <>
              <StyledDivider />
              <List
                aria-labelledby={ids[0]}
                sx={{ py: 4 }}
                subheader={
                  <StyledListSubHeader id={ids[0]}>
                    Navigator
                  </StyledListSubHeader>
                }
              >
                {showAIDataDesigner && (
                  <PrimaryLinkItem
                    name="Assistant"
                    id="Assistant"
                    data-testid="sidebar-chat"
                    // For V0 we're going straight to the chat session because we don't have content to put on a landing page
                    // in future versions we'll have a landing page for the chat with blueprint options and chat history etc.
                    to={compileLocation(CHAT_ROUTE)}
                    icon={<Assistant width={16} height={16} />}
                    disabled={!isSidebarOpen}
                    selected={checkSelected(CHAT_ROUTE.path)}
                  />
                )}
                <PrimaryLinkItem
                  name="Builder"
                  id="Builder"
                  to={compileLocation(BUILDER_LANDING_PAGE)}
                  icon={<Builder width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(BUILDER_LANDING_PAGE.path)}
                />
                {showPlaygroundLink && (
                  <PrimaryLinkItem
                    name="Playground"
                    id="Playground"
                    data-testid="sidebar-playground"
                    to={compileLocation(PLAYGROUND_ROUTE)}
                    icon={<Playground width={16} height={16} />}
                    disabled={!isSidebarOpen}
                    selected={checkSelected(PLAYGROUND_ROUTE.path)}
                  />
                )}
              </List>
            </>
          )}
          {!noProjectsView && (
            <>
              <StyledDivider />
              <List sx={{ py: 4 }}>
                <PrimaryLinkItem
                  name="Projects"
                  data-testid="sidebar-project"
                  id="ProjectList"
                  to={compileLocation(PROJECTS_LIST_ROUTE)}
                  icon={<Project width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(PROJECTS_LIST_ROUTE.path)}
                />
                <PrimaryLinkItem
                  name="Workflows"
                  data-testid="sidebar-workflows"
                  id="WorkflowsList"
                  to={compileLocation(WORKFLOWS_ROUTE)}
                  icon={<Workflow width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(WORKFLOWS_ROUTE.path)}
                />
                <PrimaryLinkItem
                  name="Connections"
                  id="ConnectionList"
                  data-testid="sidebar-connection"
                  to={compileLocation(CONNECTIONS_ROUTE)}
                  icon={<Connection width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(CONNECTIONS_ROUTE.path)}
                />
                <PrimaryLinkItem
                  name="Activity"
                  id="Activity"
                  to={compileLocation(ACTIVITY_ROUTE)}
                  icon={<Activity width={16} height={16} />}
                  disabled={!isSidebarOpen}
                  selected={checkSelected(ACTIVITY_ROUTE.path)}
                />
              </List>
            </>
          )}

          <StyledDivider />
          <List
            aria-labelledby={ids[1]}
            sx={{ py: 4 }}
            subheader={
              <StyledListSubHeader id={ids[1]}>Account</StyledListSubHeader>
            }
          >
            <PrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_API_KEY_ROUTE)}
              name="API Key"
              id="ApiKey"
              icon={<Key width={16} height={16} />}
              selected={checkSelected(USER_API_KEY_ROUTE.path, true)}
            />
            <PrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_INVITES_ROUTE)}
              name={
                <Box display="flex" justifyContent="space-between">
                  <span>Invites</span>
                  {inviteCount ? (
                    <Chip
                      label={inviteCount}
                      color="primary"
                      sx={theme => ({
                        ...theme.typography.body2Strong,
                        px: 2,
                        mr: 2,
                      })}
                    />
                  ) : null}
                </Box>
              }
              id="invites"
              icon={<User width={16} height={16} />}
              selected={checkSelected(USER_INVITES_ROUTE.path, true)}
            />
            <PrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_USAGE_ROUTE)}
              name="Usage"
              id="Usage"
              icon={<UsageReport width={16} height={16} />}
              selected={checkSelected(USER_USAGE_ROUTE.path, true)}
            />
            <PrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_BILLING_ROUTE)}
              name="Billing"
              id="Billing"
              icon={<CreditCard width={16} height={16} />}
              selected={checkSelected(USER_BILLING_ROUTE.path, true)}
            />
            <PrimaryLinkItem
              disabled={!isSidebarOpen}
              to={compileLocation(USER_ROUTE)}
              name="Settings"
              id="Settings"
              icon={<Settings width={16} height={16} />}
              selected={checkSelected(USER_ROUTE.path, true)}
            />
          </List>
        </Box>
      </AppSidebarLayout>
    );
  }

  return (
    <AppSidebarLayout>
      <Toolbar variant="dense" sx={{ mx: -6, boxShadow: 'none' }} />
      <ToggleButton />
      <Box sx={{ flex: 1, px: 4, overflowY: 'auto' }}>
        <List component="nav" aria-labelledby={ids[0]} sx={{ py: 4 }}>
          <PrimaryLinkItem
            name="Dashboard"
            id="Dashboard"
            to={compileLocation(DASHBOARD_ROUTE)}
            icon={<Dashboard width={16} height={16} />}
            disabled={!isSidebarOpen}
            selected={checkSelected(DASHBOARD_ROUTE.path)}
          />

          {!noProjectsView && (
            <>
              <PrimaryLinkItem
                name="Activity"
                id="Activity"
                to={compileLocation(ACTIVITY_ROUTE)}
                icon={<Activity width={16} height={16} />}
                disabled={!isSidebarOpen}
                selected={checkSelected(ACTIVITY_ROUTE.path)}
              />
              <PrimaryLinkItem
                name="Projects"
                data-testid="sidebar-project"
                id="ProjectList"
                to={compileLocation(PROJECTS_LIST_ROUTE)}
                icon={<Project width={16} height={16} />}
                disabled={!isSidebarOpen}
                selected={checkSelected(PROJECTS_LIST_ROUTE.path)}
              />
              <PrimaryLinkItem
                name="Blueprints"
                id="Blueprints"
                to={compileLocation(BLUEPRINTS_ROUTE)}
                icon={<Blueprint width={16} height={16} />}
                disabled={!isSidebarOpen}
                selected={checkSelected(BLUEPRINTS_ROUTE.path)}
              />
              <PrimaryLinkItem
                name="Workflows"
                data-testid="sidebar-workflows"
                id="WorkflowsList"
                to={compileLocation(WORKFLOWS_ROUTE)}
                icon={<Workflow width={16} height={16} />}
                disabled={!isSidebarOpen}
                selected={checkSelected(WORKFLOWS_ROUTE.path)}
              />
              <PrimaryLinkItem
                name="Connections"
                id="ConnectionList"
                data-testid="sidebar-connection"
                to={compileLocation(CONNECTIONS_ROUTE)}
                icon={<Connection width={16} height={16} />}
                disabled={!isSidebarOpen}
                selected={checkSelected(CONNECTIONS_ROUTE.path)}
              />
            </>
          )}
        </List>
        {showPlaygroundLink && (
          <>
            <Divider sx={{ mb: 4 }} />
            <PrimaryLinkItem
              name="Navigator"
              id="Playground"
              data-testid="sidebar-playground"
              to={compileLocation(PLAYGROUND_ROUTE)}
              icon={<Playground width={16} height={16} />}
              disabled={!isSidebarOpen}
              selected={checkSelected(PLAYGROUND_ROUTE.path)}
            />
          </>
        )}
        {showAIDataDesigner && (
          <PrimaryLinkItem
            name="Chat (Beta)"
            id="Chat"
            data-testid="sidebar-chat"
            to={compileLocation(CHAT_ROUTE)}
            icon={<GPT width={16} height={16} />}
            disabled={!isSidebarOpen}
            selected={checkSelected(CHAT_ROUTE.path)}
          />
        )}

        <StyledDivider sx={{ mt: showPlaygroundLink ? 4 : 0 }} />
        <List
          component="nav"
          aria-labelledby={ids[1]}
          sx={{ mb: 0, mt: 4, pb: 4, overflowY: 'auto' }}
          subheader={
            <ListSubheader
              id={ids[1]}
              sx={theme => ({
                ...theme.typography.body2Strong,
                color: 'text.primary',
                lineHeight: 'unset',
                marginLeft: theme.spacing(3),
                marginBottom: theme.spacing(2),
                padding: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              })}
            >
              Account
            </ListSubheader>
          }
        >
          <PrimaryLinkItem
            disabled={!isSidebarOpen}
            to={compileLocation(USER_API_KEY_ROUTE)}
            name="API Key"
            id="ApiKey"
            icon={<Key width={16} height={16} />}
            selected={checkSelected(USER_API_KEY_ROUTE.path, true)}
          />
          <PrimaryLinkItem
            disabled={!isSidebarOpen}
            to={compileLocation(USER_INVITES_ROUTE)}
            name={
              <Box display="flex" justifyContent="space-between">
                <span>Invites</span>
                {inviteCount ? (
                  <Chip
                    label={inviteCount}
                    color="primary"
                    sx={theme => ({
                      ...theme.typography.body2Strong,
                      px: 2,
                      mr: 2,
                    })}
                  />
                ) : null}
              </Box>
            }
            id="invites"
            icon={<User width={16} height={16} />}
            selected={checkSelected(USER_INVITES_ROUTE.path, true)}
          />
          <PrimaryLinkItem
            disabled={!isSidebarOpen}
            to={compileLocation(USER_USAGE_ROUTE)}
            name="Usage"
            id="Usage"
            icon={<UsageReport width={16} height={16} />}
            selected={checkSelected(USER_USAGE_ROUTE.path, true)}
          />
          <PrimaryLinkItem
            disabled={!isSidebarOpen}
            to={compileLocation(USER_BILLING_ROUTE)}
            name="Billing"
            id="Billing"
            icon={<CreditCard width={16} height={16} />}
            selected={checkSelected(USER_BILLING_ROUTE.path, true)}
          />
          <PrimaryLinkItem
            disabled={!isSidebarOpen}
            to={compileLocation(USER_ROUTE)}
            name="Settings"
            id="Settings"
            icon={<Settings width={16} height={16} />}
            selected={checkSelected(USER_ROUTE.path, true)}
          />
        </List>
        <StyledDivider />
      </Box>
    </AppSidebarLayout>
  );
};

export default AppSidebar;
