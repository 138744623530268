import {
  ActionCreatorWithOptionalPayload,
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { produce } from 'immer';
import uploadSlice, {
  ActionCreators as UploadACs,
} from 'common/Upload/uploadDuck';
import MODEL_TYPE, { LEGACY_MODEL_TYPE } from 'constants/modelType';
import { ThunkApi } from 'src/store.types';

/**
 * DataSource could be the following:
 * string - sampleDataset -- url to csv file
 * { artifactKey, fileKey, size? } -- new upload
 * { artifactKey, size_bytes, ... } pre-existing data source -- ProjectArtifact type in src/api/types/Artifact
 */
export type DataSourceObj = {
  artifactKey?: string | undefined;
  fileKey?: string;
  size_bytes?: number;
  size?: number;
  name?: string;
};
export type DataSource = string | DataSourceObj;

type ModelConfig = { config?: { models?: object[] } };

export type NewModel = {
  modelType?: MODEL_TYPE | LEGACY_MODEL_TYPE;
  projectId?: string;
  dataSource?: DataSource;
  config?: ModelConfig;
  configFileName?: string;
  modelId?: string;
  modelGuid?: string;
};

type ProjectState = {
  newModel: NewModel;
};

const initialState: ProjectState = {
  newModel: {
    modelType: undefined,
    projectId: undefined,
    dataSource: undefined,
    config: undefined,
    configFileName: 'default.yml',
    modelId: undefined,
    modelGuid: undefined,
  },
};

export const ActionCreators = {
  updateNewModel: createAction(
    'projects/models/updateNewModel'
  ) as ActionCreatorWithOptionalPayload<
    Partial<ProjectState['newModel']>,
    'projects/models/updateNewModel'
  >,
  cancelNewModel: createAsyncThunk<boolean, void, ThunkApi>(
    'projects/models/cancelNewModel',
    async (_, { dispatch, getState }) => {
      const dataSource = getState().projects.newModel.dataSource;
      if (typeof dataSource !== 'string' && dataSource?.fileKey) {
        await dispatch(uploadSlice.actions.cancelUpload(dataSource.fileKey));
      }
      return true;
    }
  ),
};

export const AUTOUSE_CONFIG = { autouse_config: { data_source: null } };
export const MODEL_TYPES_TO_AUTOUSE: (MODEL_TYPE | LEGACY_MODEL_TYPE)[] = [
  LEGACY_MODEL_TYPE.CLASSIFY,
];
/**
 * Local util method to keep newModel config up to date with changes.
 */
export const updateModelConfig = ({
  modelType,
  dataSource,
  config = {},
}: NewModel): ModelConfig & { autouse_config?: { data_source: null } } => {
  return produce(config, draft => {
    const { config } = draft;
    if (config?.models) {
      const [model] = config.models;
      const [configType] = Object.keys(model);
      model[configType].data_source =
        typeof dataSource === 'string' ? dataSource : dataSource?.artifactKey;
    }

    if (modelType && MODEL_TYPES_TO_AUTOUSE.includes(modelType)) {
      // this tells the backend to automatically create a model run for these
      // model types. once done, backend will assign an autouse_handler_id to the
      // model
      Object.assign(draft, AUTOUSE_CONFIG);
    }
  });
};

export default createSlice({
  name: 'projects',
  initialState: { ...initialState },
  reducers: {
    reset: () => ({ ...initialState }),
  },
  extraReducers: base => {
    base.addCase(ActionCreators.updateNewModel, (state, action) => {
      Object.assign(state.newModel, action.payload);
      if (
        state.newModel.modelType &&
        state.newModel.config &&
        state.newModel.dataSource
      ) {
        state.newModel.config = updateModelConfig(state.newModel);
      }
    });
    base.addCase(ActionCreators.cancelNewModel.fulfilled, state => {
      state.newModel = { ...initialState.newModel };
    });
    // Kyle's note (sanitized version): probably shouldn't do this...
    base.addCase(ActionCreators.cancelNewModel.rejected, state => {
      state.newModel = { ...initialState.newModel };
    });

    /**
     * Upload hooks
     */
    base.addCase(UploadACs.queueUploads.fulfilled, (state, action) => {
      const modelDataSource = action.payload.find(upload => upload.forModel);
      if (modelDataSource) {
        state.newModel.dataSource = modelDataSource;
      }
    });
    base.addCase(UploadACs.queueRejected.fulfilled, (state, action) => {
      const modelDataSource = action.payload.find(upload => upload.forModel);
      if (modelDataSource) {
        state.newModel.dataSource = modelDataSource;
      }
    });
    base.addCase(
      UploadACs.startUpload.fulfilled,
      (state, action: PayloadAction<{ fileKey: string; key: string }>) => {
        const { fileKey, key: artifactKey } = action.payload;
        const { dataSource } = state.newModel;
        if (typeof dataSource !== 'string' && dataSource?.fileKey === fileKey) {
          dataSource.artifactKey = artifactKey;
          if (
            state.newModel.modelType &&
            state.newModel.config &&
            state.newModel.dataSource
          ) {
            // Keep the config up to date with data source changes
            state.newModel.config = updateModelConfig(state.newModel);
          }
        }
      }
    );
  },
});
