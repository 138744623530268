import * as React from 'react';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { useButtonPaletteUpdate, useConsoleGA } from 'common/featureFlags';
import { themeOptions } from 'src/theme';
import { ThemeModeProvider } from './Provider';
import { useThemeMode } from './useThemeMode';

export * from './useThemeMode';

export const Theming = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <ThemeModeProvider>
      <ThemingInner>{children}</ThemingInner>
    </ThemeModeProvider>
  );
};

const ThemingInner = ({ children }: React.PropsWithChildren<unknown>) => {
  const { mode } = useThemeMode();
  // cleanup Feature Flag Ticket: https://gretel.atlassian.net/browse/INT-2599
  const { value: showButtonUpdate, loading } = useButtonPaletteUpdate();
  const { value: consoleGa, loading: consoleGaLoading } = useConsoleGA();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeMode =
    mode === 'auto' ? (prefersDarkMode ? 'dark' : 'light') : mode;
  const showNewFonts = !consoleGaLoading && consoleGa;

  const theme = React.useMemo(() => {
    const options = {
      ...themeOptions,
      components: {
        ...themeOptions.components,
        MuiButton:
          loading || !showButtonUpdate
            ? themeOptions.components.MuiButton
            : themeOptions.components.UpdatedMuiButton,
        MuiIconButton:
          loading || !showButtonUpdate
            ? themeOptions.components.MuiIconButton
            : themeOptions.components.UpdatedMuiIconButton,
      },
      // TODO(INT-2849): Clean up and remove FF after GA
      typography: {
        ...themeOptions.typography,
        ...(showNewFonts
          ? {
              display1: {
                ...themeOptions.typography.display1,
                fontFamily: 'Inter, system-ui, -apple-system, sans-serif',
              },
              display2: {
                ...themeOptions.typography.display2,
                fontFamily: 'Inter, system-ui, -apple-system, sans-serif',
              },
            }
          : {}),
      },
    };

    return createTheme({
      ...options,
      palette: themeOptions.palette[themeMode],
    });
  }, [loading, showButtonUpdate, themeMode, showNewFonts]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

/** Exporting a Theme provider without dependency on configcat for use in our Error Page */
export const ThemingSafe = ({ children }: React.PropsWithChildren<unknown>) => {
  /** Defaulting to use light for error palette, can be revisited once we expose dark mode to users */
  const theme = createTheme({
    ...themeOptions,
    palette: themeOptions.palette.light,
  });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
