import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  Typography,
} from '@mui/material';
import Switch from 'switch-function';
import Formatters from 'utils/formatters';
import STAGE, { isError, isLoading, isReady } from 'utils/stage';
import UploadProgressIcon from './Icon';

type Props = ListItemProps & {
  stage: STAGE;
  error?: string | object;
  size?: number;
  name?: string;
  cancelUpload?: (id?: string) => void;
  remoteFile?: boolean;
  hideProgressBar?: boolean;
};

const STAGE_LABELS = {
  [STAGE.READY]: 'Pending...',
  [STAGE.PROCESSING]: 'Processing...',
  [STAGE.UPDATING]: 'Uploading...',
  [STAGE.UPDATED]: 'Done',
  [STAGE.CANCELLED]: 'Cancelled',
  [STAGE.ERROR]: 'Could not upload.',
};

const STAGE_PROGRESS = {
  [STAGE.READY]: 0,
  [STAGE.UPDATED]: 100,
  [STAGE.CANCELLED]: 100,
  [STAGE.ERROR]: 100,
};

const UploadProgressItem = ({
  stage,
  error,
  id,
  size,
  name,
  cancelUpload,
  className,
  remoteFile,
  hideProgressBar = false,
  ...props
}: Props) => {
  return (
    <ListItem
      key={id}
      sx={{
        alignItems: 'center',
        pl: 0,
        '&:hover #cancelbutton': {
          display: 'inline-flex',
        },
      }}
      {...props}
    >
      {/* Upload icon */}
      <ListItemAvatar sx={{ mr: 3 }}>
        <Avatar
          sx={{
            // Icon Color
            color: remoteFile
              ? 'primary.main'
              : Switch(stage, {
                  [STAGE.UPDATED]: 'success.dark',
                  [STAGE.CANCELLED]: 'info.dark',
                  [STAGE.ERROR]: 'error.dark',
                  default: 'text.secondary',
                }),
            // Avatar Color
            backgroundColor: remoteFile
              ? 'primary.contrastText'
              : Switch(stage, {
                  [STAGE.UPDATED]: 'success.light',
                  [STAGE.CANCELLED]: 'info.light',
                  [STAGE.ERROR]: 'error.light',
                  default: 'background.inset',
                }),
          }}
        >
          <UploadProgressIcon stage={stage} height={16} width={16} />
        </Avatar>
      </ListItemAvatar>
      <Box
        sx={{
          flex: '1 1 auto',
          '& > *': {
            mb: 1,
            '&:last-child': {
              mb: 0,
            },
          },
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          {/* Show filename / path if we have it*/}
          {name && (
            <Typography
              noWrap
              sx={{
                color: Switch(stage, {
                  [STAGE.READY]: 'text.secondary',
                  [STAGE.CANCELLED]: 'text.secondary',
                  [STAGE.ERROR]: 'text.secondary',
                  default: 'text.primary',
                }),
                height: '28px',
              }}
            >
              {name}
            </Typography>
          )}
          <Grid item>
            {typeof size === 'number' && (
              <Typography
                noWrap
                sx={{
                  color: Switch(stage, {
                    [STAGE.READY]: 'text.secondary',
                    [STAGE.CANCELLED]: 'text.secondary',
                    [STAGE.ERROR]: 'text.secondary',
                    default: 'text.primary',
                  }),
                }}
              >
                ({Formatters.Number.bytes(size) || '?'})
              </Typography>
            )}
            {/* Cancel button is set to display:block on hover of the list item */}
            {(isReady(stage) || isLoading(stage) || isError(stage)) &&
            cancelUpload ? (
              <Button
                id="cancelbutton"
                variant="text"
                size="small"
                onClick={() => cancelUpload(id)}
                sx={{
                  display: 'none',
                  ml: 2,
                  py: 0,
                }}
              >
                Cancel
              </Button>
            ) : null}
          </Grid>
        </Grid>
        {!remoteFile && (
          <>
            {!hideProgressBar && (
              <LinearProgress
                variant={isLoading(stage) ? 'indeterminate' : 'determinate'}
                value={STAGE_PROGRESS[stage]}
                sx={{
                  '&.MuiLinearProgress-determinate > .MuiLinearProgress-bar1': {
                    bgcolor: Switch(stage, {
                      [STAGE.CANCELLED]: 'info.light',
                      [STAGE.ERROR]: 'error.light',
                      default: 'success.main',
                    }),
                  },
                }}
              />
            )}
            <Grid container alignItems="center" wrap="nowrap">
              {!(hideProgressBar && stage === STAGE.READY) && (
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    color: Switch(stage, {
                      [STAGE.UPDATED]: 'success.main',
                      [STAGE.CANCELLED]: 'info.main',
                      [STAGE.ERROR]: 'error.main',
                      default: 'text.secondary',
                    }),
                  }}
                >
                  {STAGE_LABELS[stage]}
                </Typography>
              )}
              {isError(stage) ? (
                <Typography variant="body2" noWrap sx={{ color: 'error.main' }}>
                  &nbsp;{Formatters.Other.error(error)}
                </Typography>
              ) : null}
            </Grid>
          </>
        )}
      </Box>
    </ListItem>
  );
};

export default UploadProgressItem;
