import * as React from 'react';
import { useConsoleGA } from 'common/featureFlags';
import { ShowerManager } from './Managers/ShowerManager';
import { WatcherManager } from './Managers/WatcherManager';

/** @deprecated */
export const ModelCompletionDialogManager = () => {
  const { value } = useConsoleGA();
  if (value) {
    return null;
  }

  return (
    <>
      <WatcherManager />
      <ShowerManager />
    </>
  );
};
