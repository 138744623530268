import { useFeatureFlag } from 'configcat-react';
import { useLoadProfileQuery } from 'src/api';

export enum FeatureFlags {
  HYBRID_AVAILABLE = 'hybridAvailable',
  HYBRID_ONLY = 'hybridOnly',
  PLAYGROUND_EDIT_DATASET = 'playgroundEditDataset',
  DARK_MODE = 'darkmode',
  HIDE_PLAYGROUND = 'hidePlayground',
  ENTERPRISE_PROJECT_MGMT = 'enterpriseProjectManagement', // cleanup: INT-2230
  BUTTON_PALETTE_UPDATE = 'buttonPaletteUpdate',
  NAVIGATOR_SAVED_PROMPTS = 'navigatorSavedPrompts',
  PLAYGROUND_SERVERLESS = 'dedicatedPlayground',
  WORKFLOW_BUILDER_BLUEPRINT = 'workflowBuilderBlueprint', // cleanup: https://gretel.atlassian.net/browse/INT-2430
  AI_DATA_DESIGNER_MVP = 'aiddmvp',
  ACTIVITY_LIST_UPDATE = 'activityListUpdate', // cleanup: https://gretel.atlassian.net/browse/INT-2485
  SELECT_SERVERLESS_TENANT = 'selectServerlessTenant', // md: an unasked-for feature used to test
  WORKFLOW_PREVIEW_SOURCE_DATASET = 'workflowPreviewSourceDataset', // https://gretel.atlassian.net/browse/INT-2584
  WORKFLOW_INLINE_METRIC_SCORES = 'workflowInlineMetricScores', // https://gretel.atlassian.net/browse/INT-2748
  BLOCK_DISCONTINUED_MODELS = 'blockDiscontinuedModels',
  CONSOLE_GA = 'consoleGa',
  V2_WORKFLOW_CONFIG_BUILDER = 'v2WorkflowConfigBuilder',
  TENANT_DEV_SELECTOR = 'tenantdevselector',
}

/** A hook that will return a boolean `value` for a feature flag for
 * the logged in User as well as loading status. */
type FeatureFlagHook = () => { value: boolean; loading: boolean };
const hookFactory: (f: FeatureFlags) => FeatureFlagHook = featureFlag => () => {
  const { data: userData } = useLoadProfileQuery();
  const userInfo = userData?.guid
    ? {
        email: userData.email,
        identifier: userData.guid,
        custom: { domain: userData.email.split('@').pop() || '' },
      }
    : undefined;

  const { value, loading } = useFeatureFlag(featureFlag, false, userInfo);

  return { value, loading };
};

export const useHybridFeature = hookFactory(FeatureFlags.HYBRID_AVAILABLE);

export const useOnlyHybridFeature = hookFactory(FeatureFlags.HYBRID_ONLY);

export const usePlaygroundEditDataset = hookFactory(
  FeatureFlags.PLAYGROUND_EDIT_DATASET
);

export const useDarkMode = hookFactory(FeatureFlags.DARK_MODE);

export const useHidePlayground = hookFactory(FeatureFlags.HIDE_PLAYGROUND);

export const useEnterpriseProjectManagement = hookFactory(
  FeatureFlags.ENTERPRISE_PROJECT_MGMT
);

export const useButtonPaletteUpdate = () => {
  const { value, loading } = useFeatureFlag(
    FeatureFlags.BUTTON_PALETTE_UPDATE,
    false
  );

  return { value, loading };
};

export const useNavigatorSavedPrompts = hookFactory(
  FeatureFlags.NAVIGATOR_SAVED_PROMPTS
);

// NOTE: You probably don't want to use this flag on its own.
// Instead, check out the useServerlessTenant hook in Playground.
export const useServerlessPlayground = hookFactory(
  FeatureFlags.PLAYGROUND_SERVERLESS
);

export const useWorkflowBuilderBlueprint = hookFactory(
  FeatureFlags.WORKFLOW_BUILDER_BLUEPRINT
);

export const useAIDataDesignerMVP = hookFactory(
  FeatureFlags.AI_DATA_DESIGNER_MVP
);

export const useActivityListUpdate = hookFactory(
  FeatureFlags.ACTIVITY_LIST_UPDATE
);

export const useSelectServerlessTenant = hookFactory(
  FeatureFlags.SELECT_SERVERLESS_TENANT
);

export const useWorkflowPreviewSourceDataset = hookFactory(
  FeatureFlags.WORKFLOW_PREVIEW_SOURCE_DATASET
);

export const useWorkflowInlineMetricScores = hookFactory(
  FeatureFlags.WORKFLOW_INLINE_METRIC_SCORES
);

export const useBlockDiscontinuedModels = hookFactory(
  FeatureFlags.BLOCK_DISCONTINUED_MODELS
);

export const useConsoleGA = hookFactory(FeatureFlags.CONSOLE_GA);
export const useV2WorkflowConfigBuilder = hookFactory(
  FeatureFlags.V2_WORKFLOW_CONFIG_BUILDER
);

export const useTenantDevSelector = hookFactory(
  FeatureFlags.TENANT_DEV_SELECTOR
);
