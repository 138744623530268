import { matchPath } from 'react-router';
import { LocationValidator } from 'common/utils/useOnNavigate';
import { Workflow } from 'src/api/jarvis';
import { BUILDER, WORKFLOW_DETAILS_ROUTE } from 'src/routes';

const INVALID_WORKFLOW_NAME_CHARS = /[^A-Za-z0-9_-]/g;

/** replaces invalid characters with `-` */
export const sanitizeWorkflowName = (name: string) => {
  return name.replace(INVALID_WORKFLOW_NAME_CHARS, '-');
};

export const shouldResetOnNavigate: LocationValidator = location =>
  !matchPath(location.pathname, {
    path: [BUILDER.path, WORKFLOW_DETAILS_ROUTE.path],
  });

// TODO: Replace this with a better way of knowing.
export const isDataDesignerConfig = (config: string) => {
  return config.indexOf('generated_data_columns') != -1;
};

export const isV2WorkflowConfig = (config: unknown): config is Workflow =>
  typeof config === 'object' && config !== null && config['version'] === '2';
