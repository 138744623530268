import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoaderFull from 'common/Loader/Full';
import { useLoadProfileQuery } from 'src/api';
import { LOGIN_ROUTE, Route as RouteType, SETUP_ROUTE } from 'src/routes';
import {
  compilePath_unsmart as compilePath,
  ConsoleRouteContext,
} from 'utils/useConsoleRoute';
import { RootLayout } from '../Layout';

type Props = React.PropsWithChildren<
  RouteProps & {
    route: RouteType;
    showLayout?: boolean;
    showFooter?: boolean;
    isFullHeight?: boolean;
  }
>;

const PrivateRoute = ({
  children,
  showLayout = true,
  showFooter = true,
  isFullHeight = false,
  route,
  ...rest
}: Props) => {
  const profile = useLoadProfileQuery();

  const { childComponent, ...serializableConsoleRoute } = route;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (profile.isUninitialized || profile.isLoading) {
          return (
            <div style={{ height: '100vh' }}>
              <LoaderFull />
            </div>
          );
        }
        if (profile.isSuccess) {
          if (
            !profile.data.events.OB_SURVEY_V1.complete &&
            location.pathname !== SETUP_ROUTE.path
          ) {
            return (
              <Redirect
                to={{
                  pathname: compilePath(SETUP_ROUTE),
                  state: {
                    from: {
                      consoleRoute: serializableConsoleRoute,
                      ...location,
                    },
                  },
                }}
              />
            );
          }
          if (!showLayout) {
            return (
              <ConsoleRouteContext.Provider value={route}>
                {children}
              </ConsoleRouteContext.Provider>
            );
          }
          return (
            <ConsoleRouteContext.Provider value={route}>
              <RootLayout showFooter={showFooter} isFullHeight={isFullHeight}>
                {children}
              </RootLayout>
            </ConsoleRouteContext.Provider>
          );
        } else {
          return (
            <Redirect
              to={{
                ...location,
                pathname: compilePath(LOGIN_ROUTE),
                state: {
                  from: { consoleRoute: serializableConsoleRoute, ...location },
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
