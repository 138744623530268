import * as React from 'react';
import DomainNotFound from 'common/DomainNotFound';
import { DASHBOARD_ROUTE } from 'src/routes';

const ModelsNotfound = () => {
  return (
    <DomainNotFound
      secondaryText="Looks like the server is not responding, please try again later."
      domain="models"
      action={{
        text: 'Back to dashboard',
        redirect: DASHBOARD_ROUTE,
      }}
      contentStyles={{ mt: 8 }}
    />
  );
};

export default ModelsNotfound;
