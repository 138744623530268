import { Status2 } from 'src/api/lambda/projects';

export type GetModelArtifacts = {
  guid: string;
  uid: string;
  type?: 'model' | 'report' | 'report_json' | 'data_preview';
  download?: boolean;
};

export enum RecordHandlerArtifactType {
  /** CSV/JSON data output of record handler */
  DATA = 'data',

  /** JSON Report about record handler run */
  RUN_REPORT_JSON = 'run_report_json',

  /** JSON logs of record handler */
  RUN_LOGS = 'run_logs',
}

export type GetRecordHandlerArtifacts = {
  guid: string;
  modelId: string;
  uid: string;
  type?: RecordHandlerArtifactType;
  download?: boolean;
};

export type ProjectArtifact = {
  key: string;
  artifactKey: string;
  size_bytes: number;
  last_modified: string;
};

export type ProjectArtifactUrl = {
  key: string;
  url: string;
  method: string;
};

// note: this is not a comprehensive definition, but includes fields we're likely to use
export type ProjectArtifactWithManifest = {
  artifact_id: string;
  project_id: string;
  guid: string;
  status: Status2;
  file_size?: number;
  upload_time?: string;
  manifest: {
    extension?: string; // ".csv"
    reader_type?: string; // "csv"
    record_count?: number;
    field_count?: number;
    avg_record_length?: number;
    duplicated_count?: number;
    duplicated_percent?: number;
    total_missing_count?: number;
    highly_unique_field_count?: number;
    high_missing_values_field_count?: number;
    max_avg_str_length?: {
      avg_str_length?: number;
      field?: string;
    }[];
    types?: {
      type?: string;
      count?: number;
    }[];
    max_unique?: {
      unique_count?: number;
      unique_percent?: number;
      field?: string;
    }[];
    fields?: {
      name: string;
      type: string;
      count: number;
      unique_count?: number;
      unique_percent?: number;
      missing_count?: number;
      missing_percent?: number;
      min_str_length?: number;
      max_str_length?: number;
      avg_str_length?: number;
      min_value?: number;
      max_value?: number;
      space_count?: number;
    }[];
    data_check_results?: {
      check_id?: string;
      status?: string;
      warnings?: unknown;
    }[];
  };
  error_message?: string;
};
