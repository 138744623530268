import { GretelAPI } from './GretelAPI';

export const sharedMutationKeys = {
  createModelMutation: 'shared-model-create',
  createBlueprintModelMutation: 'shared-bp-model-create',
  validateInputWorkflowAction: 'shared-input-workflow-validate',
  validateOutputWorkflowAction: 'shared-output-workflow-validate',
};

export const {
  useLoadProfileQuery,
  useProjectsQuery,
  useProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useModelsQuery,
  useModelQuery,
  useModelTypesQuery,
  useCreateModelMutation,
  useUpdateModelMutation,
  useDeleteModelMutation,
  useModelArtifactQuery,
  useRecordHandlersQuery,
  useRecordHandlerQuery,
  useCreateRecordHandlerMutation,
  useUpdateRecordHandlerMutation,
  useDeleteRecordHandlerMutation,
  useRecordHandlerArtifactQuery,
  useMembersQuery,
  useInviteMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
  useDataSourcesQuery,
  useDataSourceManifestQuery,
  useDataSourceURLQuery,
  useDataSourceFileStatsQuery,
  useDeleteDataSourceMutation,
  useSampleDatasetPreviewQuery,
  useUpdateProfileMutation,
  useDeleteUserMutation,
  useRefreshAPIKeyMutation,
  useRevokeAPIKeyMutation,
  useLoadInvitesQuery,
  useAcceptProjectInviteMutation,
  useDeclineProjectInviteMutation,
  useDeleteBillingPaymentMethodMutation,
  useSetDefaultBillingPaymentMethodMutation,
  useModifyBillingSubscriptionMutation,
  useLoadBillingQuery,
  useInitiatePaymentMethodCollectionMutation,
  useTeamMembersQuery,
  useLazyTeamMembersQuery,
  useInviteTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useAcceptTeamInviteMutation,
  useDeclineTeamInviteMutation,
  useUpdateTeamPoliciesMutation,
  useLazyLogoutQuery,
  useUseCasesQuery,
  useUpdatedUseCasesQuery,
  useUseCaseDetailsQuery,
  useInferenceModelsQuery,
  useExamplePromptsQuery,
  useTabularInferenceStreamMutation,
  useTabularInferenceStreamIterateMutation,
  useTabularInferenceEditStreamMutation,
  useNaturalLanguageInferenceMutation,
  useLicensesQuery,
  useSuggestedPromptNameMutation,
  useFilePreviewV1Query,
  useLazyFileDownloadV1Query,
  useLazyDownloadWorkflowResultsQuery,
  useLazyDownloadWorkflowStepResultsQuery,
  useWorkflowRegistryQuery,
  endpoints: {
    loadProfile: { useQueryState: useLoadProfileQueryState },
    dataSourceURL: {
      useQueryState: useDataSourceURLQueryState,
      useLazyQuery: useDataSourceURLLazyQuery,
    },
    modelArtifact: { useLazyQuery: useModelArtifactLazyQuery },
    recordHandler: { useLazyQuery: useRecordHandlerLazyQuery },
    recordHandlerArtifact: { useLazyQuery: useRecordHandlerArtifactLazyQuery },
  },
} = GretelAPI;

export * from './GretelAPI';

export default GretelAPI;
