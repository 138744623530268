import isEmpty from 'lodash/isEmpty';
import { KeyedUpload } from 'common/Upload/uploadDuck';
import { ProjectArtifact } from 'src/api/types/Artifact';
import { Model as ModelType } from 'src/api/types/Model';
import { FileUpload } from '../data';
import { DateFormatter } from './date';

export const ProjectFormatter = {
  displayName: (value?: string) => (value ? value : 'Untitled project'),
  modelName: (model?: ModelType) =>
    model?.config?.name || model?.model_name
      ? model?.config?.name || model?.model_name
      : 'Untitled model',
  modelHash: (model?: Pick<ModelType, 'model_type' | 'uid'>) =>
    model ? `${model.model_type}@${model.uid.slice(-8)}` : '',
  /**
   * @deprecated
   * make this less polymorphic?
   * currently accepts too many argument types to validate in TS.
   */
  artifactName: (
    artifact?: string | KeyedUpload | ProjectArtifact | FileUpload
  ) => {
    if (artifact) {
      let name = '';
      if (typeof artifact === 'string') {
        name = artifact;
      } else if (artifact.artifactKey) {
        name = artifact.artifactKey;
      } else if ('key' in artifact) {
        name = artifact.key;
      }

      const [first, , ...rest] = name.split('_');

      return first === 'gretel'
        ? rest.join('_')
        : (name.split('/').pop() as string); // expected to be URL
    }
    return 'Untitled Artifact';
  },
  /**
   * @deprecated
   * idea: Turn into function to be passed to map()
   * so we can use on individual log entries.  */
  logMessages: logs => {
    if (logs === undefined || logs.length === 0) {
      return '';
    }
    const logsFormatted = logs.map(log => {
      if (isEmpty(log.ctx)) {
        return `${DateFormatter.logTime(log.ts)}  ${log.msg}`;
      }
      const context = Object.entries(log.ctx as Record<string, unknown>)
        .map(([field, value]) =>
          typeof value === 'object'
            ? `${field} ${JSON.stringify(value)}`
            : `${field} ${value}`
        )
        .join(', ');
      return `${DateFormatter.logTime(log.ts)}  ${log.msg} \u2014 ${context}`;
    });
    return logsFormatted.join('\n') || '';
  },
};
