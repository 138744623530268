import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { Step, Workflow } from 'src/api/jarvis';
import { AIDDConfig, initialState, isAIDDConfig } from './constants';
import { sanitizeWorkflowName } from './utils';

export const builderV2Duck = createSlice({
  name: 'builderV2',
  initialState,
  reducers: {
    updateName: (state, action: PayloadAction<string>) => {
      state.name = sanitizeWorkflowName(action.payload);
    },
    updateSteps: (state, action: PayloadAction<Step[]>) => {
      if (!isAIDDConfig(state)) {
        state.steps = action.payload;
      }
    },
    /**
     * Used by YAML editor to overwrite all state at once.
     */
    replaceState: (state, action: PayloadAction<Workflow | AIDDConfig>) =>
      produce(action.payload, draft => {
        if (!draft.name) {
          draft.name = state.name;
        } else {
          draft.name = sanitizeWorkflowName(draft.name);
        }
      }),
    reset: () => initialState,
  },
});
