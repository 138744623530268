import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stringify } from 'yaml';
import { Trigger, TypedActionConfig } from 'src/api/pilot';
import { Store } from 'src/store.types';
import { logger } from 'utils/logger';
import {
  isModelAction,
  isOutputAction,
  isSourceAction,
} from '../Workflows/types';
import { updateActionNameReferences } from '../Workflows/utils';
import {
  MODEL_INPUT_PLACEHOLDER,
  OUTPUT_INPUT_PLACEHOLDER,
  sanitizeWorkflowName,
  SOURCE_PLACEHOLDER,
} from './utils';

export type BuilderState = {
  name: string;
  actions: TypedActionConfig[];
  trigger?: Trigger;
};

export const DEFAULT_WORKFLOW_NAME = 'my_workflow_name';
/**
 * Note: this state mirrors the shape of a Workflow Config. This allows us
 * to easily update the configuration value in whole or part. A drawback is that
 * we do not automatically recognize any new fields at the root level of a config (i.e.,
 * outside of name, actions, and trigger)
 */
export const initialState: BuilderState = {
  name: DEFAULT_WORKFLOW_NAME,
  actions: [],
  trigger: undefined,
};

export const builderDuck = createSlice({
  name: 'builder',
  initialState,
  reducers: {
    initializeHoldoutAndEvaluate: (state, action: PayloadAction<string>) => {
      state.actions = [
        {
          name: 'holdout',
          type: 'holdout',
          input: MODEL_INPUT_PLACEHOLDER,
          config: {
            dataset: `{outputs.${MODEL_INPUT_PLACEHOLDER}.dataset}`,
          },
        },
        {
          name: 'evaluate',
          type: 'evaluate',
          input: OUTPUT_INPUT_PLACEHOLDER,
          config: {
            project_id: action.payload,
            synth_dataset: `{outputs.${OUTPUT_INPUT_PLACEHOLDER}.dataset}`,
            train_dataset: `{outputs.${SOURCE_PLACEHOLDER}.dataset}`,
            holdout_dataset: '{outputs.holdout.holdout}',
          },
        },
      ];
    },
    updateName: (state, action: PayloadAction<string>) => {
      state.name = sanitizeWorkflowName(action.payload);
    },
    updateTrigger: (state, action: PayloadAction<Trigger | undefined>) => {
      state.trigger = action.payload;
    },
    updateActions: (state, action: PayloadAction<TypedActionConfig[]>) => {
      state.actions = action.payload;
    },
    /**
     *
     * Used by YAML editor to overwrite all state at once.
     */
    updateConfig: (state, action: PayloadAction<BuilderState>) => {
      const newState = action.payload;

      // Don't allow workflow name deletion.
      if (!newState.name) {
        newState.name = state.name;
      } else {
        newState.name = sanitizeWorkflowName(newState.name);
      }

      return newState;
    },
    /**
     * Add an action to the workflow, inserts at given index.
     */
    addActionByIndex: (
      state,
      action: PayloadAction<{ action: TypedActionConfig; actionIndex: number }>
    ) => {
      const {
        payload: { action: actionToBeInserted, actionIndex },
      } = action;
      if (actionIndex === 0 && isSourceAction(actionToBeInserted)) {
        state.actions.splice(actionIndex, 0, actionToBeInserted);
        if (state.actions.length > 1) {
          // we need to look for a placeholder to replace

          state.actions.forEach((currentAction, i) => {
            if (
              'input' in currentAction &&
              !!actionToBeInserted.name &&
              currentAction.input === MODEL_INPUT_PLACEHOLDER
            ) {
              state.actions[i] = updateActionNameReferences(
                currentAction,
                MODEL_INPUT_PLACEHOLDER,
                actionToBeInserted.name
              );
            }
            if (currentAction.type === 'evaluate') {
              state.actions[i] = updateActionNameReferences(
                currentAction,
                SOURCE_PLACEHOLDER,
                actionToBeInserted.name
              );
            }
          });
        }
      } else {
        /**
         * we are inserting somewhere in the middle or at the end.
         * we assume that any action using the new action's input as their own
         * input needs to be updated to reference the new action instead
         */
        state.actions.forEach((currentAction, i) => {
          if ('input' in currentAction) {
            if (
              'input' in actionToBeInserted &&
              !!actionToBeInserted.input &&
              !!actionToBeInserted.name &&
              currentAction.input === actionToBeInserted.input &&
              currentAction.name !== actionToBeInserted.name && // don't update itself
              !isOutputAction(actionToBeInserted) && // dont override inputs if we're inserting an output
              actionToBeInserted.type !== 'evaluate' // don't override inputs if we're inserting an evaluate
            ) {
              state.actions[i] = updateActionNameReferences(
                currentAction,
                actionToBeInserted.input,
                actionToBeInserted.name
              );
            } else if (
              currentAction.input === OUTPUT_INPUT_PLACEHOLDER &&
              isModelAction(actionToBeInserted)
            ) {
              state.actions[i] = updateActionNameReferences(
                currentAction,
                OUTPUT_INPUT_PLACEHOLDER,
                actionToBeInserted.name
              );
            }
          }
        });
        state.actions.splice(actionIndex, 0, actionToBeInserted);
      }
    },
    updateActionByName: (
      state,
      action: PayloadAction<{ action: TypedActionConfig; actionName: string }>
    ) => {
      const {
        payload: { action: updatedAction, actionName },
      } = action;
      try {
        const actionIndex = state.actions.findIndex(
          action => action.name === actionName
        );
        if (actionIndex === -1) {
          throw new Error("Can't find action in workflow config");
        }
        state.actions.splice(actionIndex, 1, updatedAction);

        state.actions.forEach((currentAction, i) => {
          if ('input' in currentAction) {
            if (currentAction.input === actionName && !!updatedAction.name) {
              state.actions[i] = updateActionNameReferences(
                currentAction,
                actionName,
                updatedAction.name
              );
              /**
               * If we set an output before setting the model, we need to update all outputs
               * to point to this model...
               */
            } else if (
              isModelAction(updatedAction) &&
              currentAction.input === OUTPUT_INPUT_PLACEHOLDER
            ) {
              state.actions[i] = updateActionNameReferences(
                currentAction,
                OUTPUT_INPUT_PLACEHOLDER,
                updatedAction.name
              );
            }
          }
        });
      } catch (err) {
        // error in console to be picked up by dd
        logger.error(
          `Workflow Builder: could not find action by name: ${actionName}`
        );
      }
    },
    removeActionByIndex: (
      state,
      action: PayloadAction<{ actionIndex: number }>
    ) => {
      const actionToRemove = state.actions[action.payload.actionIndex];
      if (actionToRemove) {
        // for all other actions, we need to update any references to this action
        // as their input to the input of the removed action
        // finally, remove the action.
        const prevInput =
          'input' in actionToRemove ? actionToRemove.input : undefined;
        state.actions.forEach((workflowAction, i) => {
          if (
            'input' in workflowAction &&
            workflowAction.input === actionToRemove.name
          ) {
            // in case we are deleting the source, which doesnt have an input itself...
            const backupInput = isModelAction(workflowAction)
              ? MODEL_INPUT_PLACEHOLDER
              : OUTPUT_INPUT_PLACEHOLDER;

            state.actions[i] = updateActionNameReferences(
              workflowAction,
              actionToRemove.name || '',
              prevInput ?? backupInput
            );
          }
        });
        // now, delete the action from the list
        state.actions.splice(action.payload.actionIndex, 1);
      }
    },
    removeActionByName: (
      state,
      { payload: { actionName } }: PayloadAction<{ actionName: string }>
    ) => {
      const toRemove = state.actions.find(action => action.name === actionName);
      if (!toRemove) {
        return;
      }
      const previous = 'input' in toRemove ? toRemove.input : undefined;
      state.actions.forEach((action, i) => {
        if ('input' in action && action.input === actionName) {
          const backupInput = isModelAction(action)
            ? MODEL_INPUT_PLACEHOLDER
            : OUTPUT_INPUT_PLACEHOLDER;
          state.actions[i] = updateActionNameReferences(
            action,
            actionName,
            previous ?? backupInput
          );
        }
      });
      state.actions = state.actions.filter(action => toRemove !== action);
    },
    reset: () => {
      return initialState;
    },
  },
});

export const selectBuilderState = (state: Store) => state.builder;

export const selectConfigAsString = createSelector(
  selectBuilderState,
  configState => translateStateToConfigStr(configState)
);

const translateStateToConfigStr = (state: BuilderState) => {
  const stateCopy = { ...state };
  if ('trigger' in stateCopy && stateCopy.trigger === undefined) {
    delete stateCopy['trigger'];
  }

  return stringify(stateCopy);
};

const selectActions = (state: Store) => state.builder.actions;

export const selectUnsupportedActions = createSelector(
  selectActions,
  actionState => filterOutSupportedActions(actionState)
);

const filterOutSupportedActions = (actions: TypedActionConfig[]) => {
  if (!actions || !actions.length) {
    return null;
  } else {
    const unsupported = actions.filter(action => {
      if (isSourceAction(action)) {
        return false;
      } else if (isModelAction(action)) {
        return false;
      } else if (isOutputAction(action)) {
        return false;
      } else if (action.type === 'evaluate' || action.type === 'holdout') {
        return false;
      } else {
        return true;
      }
    });

    return unsupported.length
      ? unsupported.map(a => (a.type ? a.type : 'undefined-action-type'))
      : null;
  }
};

export const selectModelActions = createSelector(selectActions, actionState =>
  actionState.filter(isModelAction)
);

export const selectOutputActions = createSelector(selectActions, actionState =>
  actionState.filter(isOutputAction)
);

export const initialStateAsString = translateStateToConfigStr(initialState);
