export const PROJECT_NAME_MAX_LENGTH = 64;

/**
 * Max file size we want to download (5 MB)
 */
export const MAX_DOWNLOADABLE_BYTES = 5242880;

export const MAX_DOWNLOADABLE_PREVIEW_ROWS = 20;

/**
 * Data display thresholds
 */
export const FIELD_SENSITIVITY_THRESHOLD = 0.6;

export const POTENTIAL_CSV_MIME_TYPES = [
  /* Potential CSV MIME Types */
  'text/plain',
  'text/x-csv',
  'application/vnd.ms-excel',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
] as const;
/*
export const POTENTIAL_JSON_MIME_TYPES = [
  'application/json',
  'application/x-javascript',
  'text/javascript',
  'text/x-javascript',
  'text/x-json',
  'text/json',
];
export const POTENTIAL_JSONL_MIME_TYPES = [
  'application/jsonl',
  'application/json-lines',
  'application/jsonlines',
  'application/json-seq',
  'application/ndjson',
  'application/javascript-lines',
  'application/x-jsonl',
  'application/x-json-lines',
  'application/x-jsonlines',
  'application/x-json-seq',
  'application/x-ndjson',
  'application/x-javascript-lines',
  'text/jsonl',
  'text/json-lines',
  'text/jsonlines',
  'text/json-seq',
  'text/ndjson',
  'text/javascript-lines',
  'text/x-jsonl',
  'text/x-json-lines',
  'text/x-jsonlines',
  'text/x-json-seq',
  'text/x-ndjson',
  'text/x-javascript-lines',
];
*/
export const YAML_MIME_TYPES = [
  'text/x-yaml',
  'text/yaml',
  'text/yml',
  'application/x-yaml',
  'application/x-yml',
  'application/yaml',
  'application/yml',
] as const;

export enum FILE_TYPES {
  CSV = 'csv',
  JSON = 'json',
  JSONL = 'jsonl',
  YAML = 'yaml',
  PARQUET = 'parquet',
  UNSUPPORTED = '',
}

export const CSV_EXTENSION_REGEX = /\.csv$/;
export const JSON_EXTENSION_REGEX = /\.json$/;
export const JSONL_EXTENSION_REGEX = /\.jsonl$/;
export const PARQUET_EXTENSION_REGEX = /\.parquet$/;
export const YAML_EXTENSION_REGEX = /\.ya?ml$/;

export const FILENAME_REGEX = /^[a-zA-Z0-9-_.\s()[\]]{3,64}$/;

export const INVALID_FILENAME_ERROR = {
  code: 'file-invalid-name',
  message: `File name can only contain alphanumerics & some punctuation.`,
} as const;

export const INVALID_FILE_TYPE_ERROR = {
  code: 'file-invalid-type',
  message: `File type must be CSV or JSON(L).`,
} as const;

export type DataError =
  | typeof INVALID_FILENAME_ERROR
  | typeof INVALID_FILE_TYPE_ERROR;

export const DROPZONE_ACCEPT_YAML_FILES = YAML_MIME_TYPES.reduce(
  (accumulator, currentVal) => {
    accumulator[currentVal] = ['.yml', '.yaml'];
    return accumulator;
  },
  {}
);
