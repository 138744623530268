export enum ActionSummaryStatus {
  RUN_STATUS_UNKNOWN = 'RUN_STATUS_UNKNOWN',
  RUN_STATUS_CREATED = 'RUN_STATUS_CREATED',
  RUN_STATUS_PENDING = 'RUN_STATUS_PENDING',
  RUN_STATUS_ACTIVE = 'RUN_STATUS_ACTIVE',
  RUN_STATUS_ERROR = 'RUN_STATUS_ERROR',
  RUN_STATUS_LOST = 'RUN_STATUS_LOST',
  RUN_STATUS_COMPLETED = 'RUN_STATUS_COMPLETED',
  RUN_STATUS_CANCELLING = 'RUN_STATUS_CANCELLING',
  RUN_STATUS_CANCELLED = 'RUN_STATUS_CANCELLED',
  RUN_STATUS_SKIPPED = 'RUN_STATUS_SKIPPED',
}
export enum ActivityEventPredicate {
  PREDICATE_CREATED_AT = 'PREDICATE_CREATED_AT',
}
export enum ClusterCloudProviderType {
  UNKNOWN = 'UNKNOWN',
  AWS = 'AWS',
  GCP = 'GCP',
  AZURE = 'AZURE',
}
export enum ClusterStatusHealthStatus {
  HEALTH_STATUS_UNKNOWN = 'HEALTH_STATUS_UNKNOWN',
  HEALTH_STATUS_HEALTHY = 'HEALTH_STATUS_HEALTHY',
  HEALTH_STATUS_DEGRADED = 'HEALTH_STATUS_DEGRADED',
  HEALTH_STATUS_UNHEALTHY = 'HEALTH_STATUS_UNHEALTHY',
}
export enum ConfigAsymmetricKeyMetadataAlgorithm {
  UNKNOWN_ALGORITHM = 'UNKNOWN_ALGORITHM',
  RSA_4096_OAEP_SHA256 = 'RSA_4096_OAEP_SHA256',
}
export enum ConnectionValidationStatus {
  VALIDATION_STATUS_UNKNOWN = 'VALIDATION_STATUS_UNKNOWN',
  VALIDATION_STATUS_VALIDATING = 'VALIDATION_STATUS_VALIDATING',
  VALIDATION_STATUS_VALID = 'VALIDATION_STATUS_VALID',
  VALIDATION_STATUS_INVALID = 'VALIDATION_STATUS_INVALID',
}
export enum CreateNavigatorPromptRequestType {
  PROMPT_TYPE_GPT = 'PROMPT_TYPE_GPT',
  PROMPT_TYPE_TABULAR = 'PROMPT_TYPE_TABULAR',
}
export enum CreateServerlessTenantRequestTenantType {
  UNKNOWN = 'UNKNOWN',
  CUSTOMER = 'CUSTOMER',
  DEMO = 'DEMO',
  TESTING = 'TESTING',
  SANDBOX = 'SANDBOX',
}
export enum CreateServerlessTenantRequestState {
  TENANT_STATE_UNKNOWN = 'TENANT_STATE_UNKNOWN',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}
export enum CreateWorkflowRequestRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum NavigatorPromptType {
  PROMPT_TYPE_GPT = 'PROMPT_TYPE_GPT',
  PROMPT_TYPE_TABULAR = 'PROMPT_TYPE_TABULAR',
}
export enum ProjectRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum ServerlessTenantCloudProviderInfoProvider {
  UNKNOWN = 'UNKNOWN',
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
}
export enum ServerlessTenantConfigTenantType {
  UNKNOWN = 'UNKNOWN',
  CUSTOMER = 'CUSTOMER',
  DEMO = 'DEMO',
  TESTING = 'TESTING',
  SANDBOX = 'SANDBOX',
}
export enum ServerlessTenantConfigState {
  TENANT_STATE_UNKNOWN = 'TENANT_STATE_UNKNOWN',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}
export enum ServerlessTenantProvisioningStateStatus {
  PROVISIONING_STATUS_UNKNOWN = 'PROVISIONING_STATUS_UNKNOWN',
  UNPROVISIONED = 'UNPROVISIONED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}
export enum ServerlessTenantProvisioningStateEncryptionKey {
  KEY_UNKNOWN = 'KEY_UNKNOWN',
  GRETEL_MANAGED = 'GRETEL_MANAGED',
  CUSTOMER_KEY = 'CUSTOMER_KEY',
  INVALID_KEY = 'INVALID_KEY',
}
export enum UpdateNavigatorPromptRequestType {
  PROMPT_TYPE_GPT = 'PROMPT_TYPE_GPT',
  PROMPT_TYPE_TABULAR = 'PROMPT_TYPE_TABULAR',
}
export enum UpdateServerlessTenantRequestTenantType {
  UNKNOWN = 'UNKNOWN',
  CUSTOMER = 'CUSTOMER',
  DEMO = 'DEMO',
  TESTING = 'TESTING',
  SANDBOX = 'SANDBOX',
}
export enum UpdateServerlessTenantRequestState {
  TENANT_STATE_UNKNOWN = 'TENANT_STATE_UNKNOWN',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}
export enum ValidateConnectionCredentialsResponseStatus {
  VALIDATION_STATUS_UNKNOWN = 'VALIDATION_STATUS_UNKNOWN',
  VALIDATION_STATUS_VALIDATING = 'VALIDATION_STATUS_VALIDATING',
  VALIDATION_STATUS_VALID = 'VALIDATION_STATUS_VALID',
  VALIDATION_STATUS_INVALID = 'VALIDATION_STATUS_INVALID',
}
export enum ValidateWorkflowActionResponseStatus {
  VALIDATION_STATUS_UNKNOWN = 'VALIDATION_STATUS_UNKNOWN',
  VALIDATION_STATUS_VALIDATING = 'VALIDATION_STATUS_VALIDATING',
  VALIDATION_STATUS_VALID = 'VALIDATION_STATUS_VALID',
  VALIDATION_STATUS_INVALID = 'VALIDATION_STATUS_INVALID',
}
export enum ValidateWorkflowConfigRequestRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum WorkflowRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum WorkflowRunRunnerMode {
  RUNNER_MODE_UNSET = 'RUNNER_MODE_UNSET',
  RUNNER_MODE_CLOUD = 'RUNNER_MODE_CLOUD',
  RUNNER_MODE_HYBRID = 'RUNNER_MODE_HYBRID',
  RUNNER_MODE_INVALID = 'RUNNER_MODE_INVALID',
}
export enum WorkflowRunStatus {
  RUN_STATUS_UNKNOWN = 'RUN_STATUS_UNKNOWN',
  RUN_STATUS_CREATED = 'RUN_STATUS_CREATED',
  RUN_STATUS_PENDING = 'RUN_STATUS_PENDING',
  RUN_STATUS_ACTIVE = 'RUN_STATUS_ACTIVE',
  RUN_STATUS_ERROR = 'RUN_STATUS_ERROR',
  RUN_STATUS_LOST = 'RUN_STATUS_LOST',
  RUN_STATUS_COMPLETED = 'RUN_STATUS_COMPLETED',
  RUN_STATUS_CANCELLING = 'RUN_STATUS_CANCELLING',
  RUN_STATUS_CANCELLED = 'RUN_STATUS_CANCELLED',
  RUN_STATUS_SKIPPED = 'RUN_STATUS_SKIPPED',
}
export enum WorkflowTaskStatus {
  RUN_STATUS_UNKNOWN = 'RUN_STATUS_UNKNOWN',
  RUN_STATUS_CREATED = 'RUN_STATUS_CREATED',
  RUN_STATUS_PENDING = 'RUN_STATUS_PENDING',
  RUN_STATUS_ACTIVE = 'RUN_STATUS_ACTIVE',
  RUN_STATUS_ERROR = 'RUN_STATUS_ERROR',
  RUN_STATUS_LOST = 'RUN_STATUS_LOST',
  RUN_STATUS_COMPLETED = 'RUN_STATUS_COMPLETED',
  RUN_STATUS_CANCELLING = 'RUN_STATUS_CANCELLING',
  RUN_STATUS_CANCELLED = 'RUN_STATUS_CANCELLED',
  RUN_STATUS_SKIPPED = 'RUN_STATUS_SKIPPED',
}
