import { Workflow } from 'src/api/jarvis';

export const DEFAULT_WORKFLOW_NAME = 'my_workflow_name';

export const SOURCE_PLACEHOLDER = 'SOURCE_PLACEHOLDER';
export const MODEL_INPUT_PLACEHOLDER = 'MODEL_INPUT_PLACEHOLDER';
export const OUTPUT_INPUT_PLACEHOLDER = 'OUTPUT_INPUT_PLACEHOLDER';

export type AIDDConfig = {
  name?: string;
  // TODO: Replace this with real typing for this object once we have it from the BE.
  rawText: string;
  // steps?: any[];
};

export const isAIDDConfig = (
  config: Workflow | AIDDConfig
): config is AIDDConfig => 'rawText' in config && !!config.rawText;

export const initialState: Workflow | AIDDConfig = {
  name: DEFAULT_WORKFLOW_NAME,
  version: '2',
  inputs: {},
  steps: [],
};
