import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link as InternalLink } from 'react-router-dom';
import {
  alpha,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(0.5),
  ':disabled, .MuiListItemButton-root:disabled': {
    pointerEvents: 'none',
  },
  '.MuiListItemButton-root': {
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.radii.medium,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.secondary.main,
        theme.palette.action.hoverOpacity
      ),
    },
    '&.Mui-selected,&:hover.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.secondary.main,
        theme.palette.action.selectedOpacity
      ),
    },
  },
  ':focus, .MuiListItemButton-root:focus': {
    backgroundColor: 'inherit',
    borderRadius: theme.shape.radii.medium,
    boxShadow: theme.palette.action.focusBoxShadow,
    zIndex: theme.zIndex.tooltip, // Elevate element to avoid box-shadow clipping caused by nearby elements.
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '& path': {
    vectorEffect: 'non-scaling-stroke', // To fix a stroke scaling issue
  },
}));

export const PrimaryLinkItem = ({
  name,
  to,
  icon,
  disabled,
  id,
  selected = false,
}: {
  name: React.ReactNode;
  id: string;
  icon: React.ReactNode;
  disabled: boolean;
  to: RouteComponentProps['location'];
  selected?: boolean;
}) => {
  return (
    <StyledListItem>
      <ListItemButton
        id={`Sidebar_${id}`}
        component={InternalLink}
        to={to}
        disabled={disabled}
        selected={selected}
      >
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <ListItemText
          primary={name}
          slotProps={{
            primary: { variant: 'body1Medium' },
          }}
        />
      </ListItemButton>
    </StyledListItem>
  );
};
