import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import {
  Box,
  Button,
  Chip,
  ListSubheader,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { useTenantDevSelector } from 'common/featureFlags';
import { AlertCircle, ArrowsExpand, Check, Copy } from 'icons/figma';
import { useLoadProfileQuery } from 'src/api';
import { copyToClipboard } from 'src/common/utils/copyToClipboard';
import { useAppSelector } from 'src/store.types';
import { TenantSelect } from './TenantSelect';

export const UserTenantBadge = () => {
  const { selected: tenant } = useAppSelector(store => store.tenants);
  const { data: user, isLoading } = useLoadProfileQuery();

  if (isLoading) {
    return <Skeleton width={200} />;
  }

  if (!tenant) {
    return (
      <>
        <Typography variant="body2Medium">
          {user?.firstname || '-'} {user?.lastname || '-'}
        </Typography>
        <Chip
          size="small"
          label="Cloud"
          color="default"
          sx={theme => ({ marginLeft: theme.spacing(2) })}
        />
      </>
    );
  }

  const rows = [
    {
      label: 'Name',
      value: tenant.name,
      copy: true,
    },
    {
      label: 'Provider',
      value: tenant.cloud_provider.provider,
    },
    {
      label: 'Region',
      value: tenant.cloud_provider.region,
    },
    {
      label: 'API Endpoint',
      value: tenant.config.api_endpoint,
      copy: true,
    },
  ];

  return (
    <TenantInfo rows={rows}>
      <Typography variant="body2Medium">
        {tenant.name} ({tenant.cloud_provider.provider}{' '}
        {tenant.cloud_provider.region})
      </Typography>
      <Chip
        size="small"
        label="Enterprise"
        color="warning"
        sx={theme => ({ marginLeft: theme.spacing(2) })}
      />
    </TenantInfo>
  );
};

type TenantInfoProps = {
  rows: {
    label: string;
    value: string;
    copy?: boolean;
  }[];
  children: React.ReactNode;
};
const TenantInfo: React.FC<TenantInfoProps> = ({ rows, children }) => {
  const [open, setOpen] = useState(false);
  const { value: showTenantSelector, loading: loadingTenantSelector } =
    useTenantDevSelector();
  const ref = useRef<HTMLButtonElement | null>(null);
  const { copy, getCopyStatus, enabled } = useCopy();
  const theme = useTheme();
  const size = 18;

  return (
    <>
      <Button
        variant="text"
        ref={ref}
        onClick={() => setOpen(!open)}
        sx={{
          backgroundColor: open ? theme.palette.action.hover : undefined,
        }}
      >
        {children}
        <Box sx={{ marginLeft: theme.spacing(2), maxHeight: size }}>
          <ArrowsExpand height={size} width={size} />
        </Box>
      </Button>
      <Menu
        open={open}
        anchorEl={ref?.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ width: '600px' }}
      >
        <ListSubheader>
          <Typography variant="body2Strong">
            Enterprise tenant details
          </Typography>
        </ListSubheader>
        {rows.map((row, index) => {
          const copyable = !!row.copy && enabled;
          const tip = !copyable
            ? ''
            : getCopyStatus(index) == 'copy'
              ? 'Copy to clipboard'
              : 'Copied';
          return (
            <Tooltip key={index} title={tip}>
              <MenuItem
                key={index}
                onClick={() => copy(index, row.value)}
                sx={{ cursor: copyable ? 'pointer' : 'default' }}
              >
                <TenantInfoItem
                  row={row}
                  copyable={copyable}
                  status={getCopyStatus(index)}
                />
              </MenuItem>
            </Tooltip>
          );
        })}
        {showTenantSelector && !loadingTenantSelector && (
          <MenuItem>
            <TenantSelect />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const TenantInfoItem: React.FC<{
  copyable: boolean;
  row: TenantInfoProps['rows'][number];
  status: CopyIconProps['status'];
}> = ({ row, status, copyable }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
      <Typography variant="body2" sx={{ minWidth: '100px' }}>
        {row.label}
      </Typography>
      <Typography
        variant="body2Medium"
        sx={{
          flexGrow: 1,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          '&.MuiTypography-root': {
            margin: theme.spacing(0, 3),
          },
        }}
      >
        {row.value || '-'}
      </Typography>
      {copyable && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CopyIcon status={status} />
        </Box>
      )}
    </Stack>
  );
};

type CopyIconProps = {
  status: 'copy' | 'copied' | 'error';
};
const CopyIcon: React.FC<CopyIconProps> = ({ status }) => {
  const size = 18;
  const sizeProps = { height: size, width: size };
  if (status === 'error') {
    return <ErrorIcon {...sizeProps} />;
  }
  return (
    <Box sx={{ maxHeight: size }}>
      {status === 'copy' ? <Copy {...sizeProps} /> : <Check {...sizeProps} />}
    </Box>
  );
};

const ErrorIcon = styled(AlertCircle)(({ theme }) => ({
  '& path': {
    stroke: theme.palette.error.main,
  },
}));

const useCopy = () => {
  const [copyIndex, setCopyIndex] = useState<number | null>(null);
  const [copyStatus, setCopyStatus] = useState<CopyIconProps['status']>('copy');
  const [copyTimeout, setCopyTimeout] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  const enabled = !!navigator.clipboard;

  const copy = useCallback(
    async (index, value) => {
      clearTimeout(copyTimeout);
      try {
        await copyToClipboard(value);
        setCopyStatus('copied');
      } catch (error) {
        toast.error('Failed to copy', { duration: 3000 });
        setCopyStatus('error');
      }
      setCopyIndex(index);
      setCopyTimeout(setTimeout(() => setCopyIndex(null), 3000));
    },
    [copyTimeout]
  );

  const getCopyStatus = (index: number) =>
    copyIndex === index ? copyStatus : 'copy';

  return { copy, getCopyStatus, enabled };
};
