/* eslint react-hooks/exhaustive-deps: 'off' */
/** @todo turn back on and fix exhaustive-deps for useCallback/useMemo/useEffect @see INT-483 */
import React, { useEffect, useState } from 'react';
import { Box, Link, Tooltip, useTheme } from '@mui/material';
import { STATUS_PAGE } from 'src/common/constants/urls';
import { logger } from 'utils/logger';

const pageID = process.env.STATUS_PAGE_ID;

declare global {
  interface Window {
    StatusPage: {
      /** this isn't our code, it's Atlassian's and for whatever
       * reason they didn't make types for StatusPage.io
       *
       * for "documentation"
       * @see https://status.lightspeedhq.com/api/v2#status
       * -walston
       **/

      page: new (options: { page?: string }) => {
        status: (callbacks: {
          success: (status: { status: { description: string } }) => void;
          error: (err: unknown) => void;
        }) => void;
      };
    };
  }
}

const { StatusPage } = window;

const UNAVAILABLE = 'Currently Unavailable';
const LOADING = 'Loading';

const FooterAPIStatus = () => {
  const theme = useTheme();
  const statusMap = {
    'All Systems Operational': theme.palette.success.main,
    'Partial System Outage': theme.palette.warning.main,
    'Minor Service Outage': theme.palette.warning.main,
    'Major Service Outage': theme.palette.error.main,
    LOADING: theme.palette.text.disabled,
    UNAVAILABLE: theme.palette.text.disabled,
  };
  const [status, setStatus] = useState(LOADING);

  useEffect(() => {
    const handleError = (err: unknown, context?: object) => {
      logger.error(err, context);
      setStatus(UNAVAILABLE);
    };

    try {
      if (!StatusPage) {
        setStatus(UNAVAILABLE);
        return;
      }

      const sp = new StatusPage.page({ page: pageID });

      if (!sp) {
        handleError(new Error('StatusPage failed to load'));
        return;
      }

      sp.status({
        success: ({ status: { description } }) => setStatus(description),
        error: (err: unknown) => {
          // Since we have no idea what kinds of errors we get back, this attempts to
          // sus out some info before passing it on to DD. -md
          if (err instanceof Error) {
            handleError(err);
          } else if (typeof err === 'object' && err) {
            handleError(new Error('StatusPage returned an error'), err);
          } else {
            handleError(
              new Error(`StatusPage returned an error: ${JSON.stringify(err)}`)
            );
          }
        },
      });
    } catch (err) {
      handleError(err);
    }
  }, []);

  return (
    <Box display="flex" alignItems="baseline" gap={1}>
      <Link
        variant="body2"
        href={STATUS_PAGE}
        color="text.secondary"
        sx={{ '&:hover': { textDecoration: 'none' } }}
      >
        API Status
      </Link>
      <Tooltip variant="dark" title={`API Status: ${status}`} placement="top">
        <Box
          id="api-status"
          sx={{
            display: 'flex',
            width: 16,
            height: 16,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: statusMap[status],
              borderRadius: '50%',
              ml: 1,
              height: 8,
              width: 8,
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default FooterAPIStatus;
