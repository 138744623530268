import { USER_ACCESS_LEVEL } from 'constants/userAccessLevel';
import { BasePagingType } from './common';
import { User } from './User';

export type ProjectType = 'hybrid' | 'cloud';

export type Project = {
  guid: string;
  _id: string;
  name: string;
  display_name: string;
  long_description: string;
  description?: string;
  owner: string;
  public: boolean;
  created: string;
  modified: string;
  membership?: {
    _id: string;
    user_email: string;
    level: number;
    project_id: string;
    invited_at: string;
    invite: boolean;
    invited_by: string;
    last_modified: string;
    project_guid: string;
    project_name: string;
    user_id: string;
    v: number;
    display_name: string;
  };
  level?: USER_ACCESS_LEVEL;
  /** withMembers === true: {users, member_count, member_remain_count} are expected */
  users?: User[];
  // Total number of members
  member_count?: number;
  // Member remain is the number of total members minus users.length
  member_remain_count?: number;
  /** withModels === true: {model_count} is expected */
  model_count?: number;
  artifact_count?: number;
  service_limits?: {
    max_project_artifacts: number;
  };
  runner_mode?: ProjectType;
  cluster_guid?: string;
};

export const hasProjectAccess = (
  project: Project | undefined,
  level: USER_ACCESS_LEVEL
) => {
  if (!project?.level) {
    return false;
  }
  return project.level >= level;
};

export type GetProjectsParams = BasePagingType & {
  /**
   * When true, returns the number of models associated with the project
   */
  withModels?: boolean;

  withArtifacts?: boolean;
  /**
   * When true, returns projects owned by the organization
   */
  ownedByDomain?: boolean;
  /**
   * When a value is supplied it will return projects whose name or description
   * match the value.
   */
  query?: string;
};

export type GetProjectArgs = {
  /**
   * Returns the Project whose guid matches this
   */
  guid: string;
  /**
   * _expand_ should be a comma separated string containing items
   * from [members, models, artifacts]
   */
  expand?: string;
};

export type ProjectCreateOrPatch = Partial<{
  name: string;
  public: string;
  description: string;
  display_name: string;
  long_description: string;
  color: string;
  runner_mode: ProjectType;
  cluster_guid?: string;
}>;
