import React, { useCallback, useEffect, useMemo } from 'react';
import { MenuItem, Select } from '@mui/material';
import { produce } from 'immer';
import { isReady } from 'common/utils/stage';
import { useLoadProfileQuery } from 'src/api';
import { ServerlessTenant } from 'src/api/pilot/serverless';
import { initializeSelected, setSelected } from 'src/api/tenants';
import { resetAllApiState } from 'src/api/utils/reset';
import { useAppDispatch, useAppSelector } from 'src/store.types';

export const TenantSelect = () => {
  const dispatch = useAppDispatch();

  const {
    tenants: _tenants,
    selected,
    stage,
  } = useAppSelector(store => store.tenants);
  const { data: user } = useLoadProfileQuery();

  // NB this is a convenience hack for dev testing.  Cloud should not be in this list when/if the selector is released.
  const tenants = useMemo(() => {
    const cloud = {
      name: `${user?.firstname} ${user?.lastname}`,
      guid: 'cloud',
      config: { api_endpoint: process.env.BACKEND_URL },
      cloud_provider: {
        provider: 'Cloud',
        region: '',
      },
    };

    return produce(_tenants, draft => {
      draft.push(cloud as ServerlessTenant);
    });
  }, [_tenants, user?.firstname, user?.lastname]);

  const handleChange = useCallback(
    (next: string) => {
      const tenant = tenants.find(({ guid }) => guid === next);
      if (tenant) {
        dispatch(setSelected(tenant));

        // Reset all API states to ensure we're using the correct API endpoint.
        dispatch(resetAllApiState());
      }
    },
    [dispatch, tenants]
  );

  useEffect(() => {
    if (isReady(stage)) {
      dispatch(initializeSelected());
    }
  }, [stage, dispatch]);

  if (!selected || !tenants.length) {
    return null;
  }

  return (
    <>
      <Select
        value={selected.guid}
        onChange={e => handleChange(e.target.value)}
        sx={{ mr: 2, flexGrow: 1 }}
      >
        {tenants.map(({ guid, name, cloud_provider }) => (
          <MenuItem key={guid} value={guid}>
            {name} ({cloud_provider.provider} {cloud_provider.region})
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
