import { matchPath } from 'react-router';
import { useProjectQuery } from 'src/api';
import { SELECTED_PROJECT_REQUIRED_ROUTES } from 'src/routes';
import useConsoleRoute from './useConsoleRoute';

/** @TODO console GA consoleGA cleanup -- not needed after console GA release */
type Expansions = 'models';

const useSelectedProject = ({ expand }: { expand?: Expansions } = {}) => {
  const {
    consoleRoute,
    location: { pathname },
  } = useConsoleRoute({ withRouterContext: true });
  // Note: this check is important because the match path will match
  // ANYTHING that is the first item in the url. e.g., /dashboard, /connections
  const isProjectRoute =
    SELECTED_PROJECT_REQUIRED_ROUTES.includes(consoleRoute);
  const match = matchPath<{ guid?: string }>(pathname, {
    path: `/:guid/`,
  });
  const guid = match?.params.guid;
  const {
    data: selectedProject,
    isError,
    isLoading,
    isUninitialized,
  } = useProjectQuery(
    { guid: guid as string, ...(expand ? { expand: 'models' } : {}) },
    { skip: !isProjectRoute || !guid }
  );

  return { isError, isLoading, isUninitialized, selectedProject };
};

export default useSelectedProject;
