import * as React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TabPanel } from 'common/TabPanel';
import { Model } from 'src/api/types/Model';
import { Score } from 'src/api/types/Report';
import Formatters from 'utils/formatters';
import { InviteTabContent } from './tabContent/InviteTabContent';
import { NextStepsTabContent } from './tabContent/NextStepsTabContent';
import { NextStepsTabNotSoSuccessContent } from './tabContent/NextStepsTabNotSoSuccessContent';
import { Layout } from './Layout';
import { SuccessLevel } from './types';

export type Props = {
  isOpen: boolean;
  model: Model;
  score: Score;
  onClose: () => void;
  onInviteSubmit: ({ email, level }: { email: string; level: number }) => void;
};
const SCORE_THRESHOLD = 60;

export const ModelCompletionDialog = ({
  isOpen,
  onClose,
  model,
  score,
  onInviteSubmit,
}: Props) => {
  const [tabValue, setTabValue] = React.useState<number>(0);
  const handleTabChange = (_: unknown, tabIndex: number) =>
    setTabValue(tabIndex);

  const modelName = Formatters.Project.modelName(model);

  if (
    (score.score > 10 && score.score >= SCORE_THRESHOLD) ||
    (score.score <= 10 && score.score >= SCORE_THRESHOLD / 10)
  ) {
    const successLevel = SuccessLevel.Success;
    return (
      <Layout
        successLevel={successLevel}
        isOpen={isOpen}
        onClose={onClose}
        score={score}
      >
        <>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ textAlign: 'center' }}
          >
            You have successfully trained{' '}
            <Typography
              component="span"
              color="text.secondary"
              variant="body2Strong"
            >
              {modelName}
            </Typography>
            .
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'border.default' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="model-completion-action-tabs"
              sx={{ mt: 8 }}
            >
              <Tab label="Next Steps" />
              <Tab label="Invite teammates" />
            </Tabs>
          </Box>
          <TabPanel
            value={tabValue}
            index={0}
            sx={{ py: 7 }}
            tabIdentifier="model-completion-nextsteps"
          >
            <NextStepsTabContent
              modelName={modelName}
              modelUid={model.uid}
              projectGuid={model.project_guid}
              artifacts={model.artifacts}
            />
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={1}
            sx={{ py: 7 }}
            tabIdentifier="model-completion-invites"
          >
            <InviteTabContent
              onSubmit={onInviteSubmit}
              successLevel={successLevel}
            />
          </TabPanel>
        </>
      </Layout>
    );
  } else {
    const successLevel = SuccessLevel.NotSoSuccess;
    return (
      <Layout
        successLevel={successLevel}
        isOpen={isOpen}
        onClose={onClose}
        score={score}
      >
        <>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ textAlign: 'center' }}
          >
            You have trained{' '}
            <Typography
              component="span"
              color="text.secondary"
              variant="body2Strong"
            >
              {modelName}
            </Typography>
            . Looking for ways to improve the Synthetic Data Quality Score (SQS)
            of the model? Here are some helpful next steps:
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'border.default' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="model-completion-action-tabs"
              sx={{ mt: 8 }}
            >
              <Tab label="Next Steps" />
              <Tab label="Invite teammates" />
            </Tabs>
          </Box>
          <TabPanel
            value={tabValue}
            index={0}
            sx={{ py: 7 }}
            tabIdentifier="model-completion-next-steps"
          >
            <NextStepsTabNotSoSuccessContent />
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={1}
            sx={{ py: 7 }}
            tabIdentifier="model-completion-invites"
          >
            <InviteTabContent
              onSubmit={onInviteSubmit}
              successLevel={successLevel}
            />
          </TabPanel>
        </>
      </Layout>
    );
  }
};
