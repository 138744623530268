import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { Connection, ConnectionsAPI } from 'src/api/pilot/connections';
import { ProjectType } from 'src/api/types/Project';
import { calculateNextDefaultName, ConnectionType } from '../utils';
import { initialAzureForm } from './steps/Forms/Azure/initialState';
import { initialBigQueryForm } from './steps/Forms/BigQuery/initialState';
import { initialDatabricksForm } from './steps/Forms/Databricks/initialState';
import { initialGCSForm } from './steps/Forms/GCS/initialState';
import { initialMsSQLForm } from './steps/Forms/MsSQL/initialState';
import { initialMySQLForm } from './steps/Forms/MySQL/initialState';
import { initialOracleForm } from './steps/Forms/Oracle/initialState';
import { initialPostgresForm } from './steps/Forms/Postgres/initialState';
import { initialS3Form } from './steps/Forms/S3/initialState';
import { initialSnowflakeForm } from './steps/Forms/Snowflake/initialState';
import { apiToForm } from './transform';
import { AllConnectionForms, ConnectionErrors, WizardSteps } from './types';

export type ConnectionWizardState = {
  step: WizardSteps;
  project?: {
    id?: string;
    displayName?: string;
    type: ProjectType;
    clusterGuid?: string;
  };
  connectionId?: string; // Possible when editing
  connectionType: ConnectionType;
  connectionForm?: AllConnectionForms;
  errors?: ConnectionErrors;
};

const DEFAULT_TYPE = 's3';

export const initialForms = {
  azure: initialAzureForm,
  bigquery: initialBigQueryForm,
  gcs: initialGCSForm,
  mssql: initialMsSQLForm,
  mysql: initialMySQLForm,
  oracle: initialOracleForm,
  postgres: initialPostgresForm,
  s3: initialS3Form,
  aws: initialS3Form,
  snowflake: initialSnowflakeForm,
  databricks: initialDatabricksForm,
};

export const initialState: ConnectionWizardState = {
  step: 'connectionType',
  project: undefined,
  connectionId: undefined,
  connectionType: DEFAULT_TYPE,
  connectionForm: undefined,
  errors: undefined,
};

export const ActionCreators = {
  setDefaultForm: createAsyncThunk(
    'connectionWizard/setDefaultForm',
    async (
      { connectionType }: { connectionType: ConnectionType },
      { dispatch }
    ) => {
      const { data } = await dispatch(
        ConnectionsAPI.endpoints.listConnections.initiate()
      ).unwrap();
      const defaultConnectionName = calculateNextDefaultName(
        connectionType,
        data
      );
      return {
        connectionType,
        name: defaultConnectionName,
      };
    }
  ),
};

const connectionWizardDuck = createSlice({
  name: 'connectionWizard',
  initialState,
  reducers: {
    toNextStepFrom: (
      state: ConnectionWizardState,
      action: PayloadAction<WizardSteps>
    ) => {
      const step = action.payload;
      if (step === 'connectionType') {
        if (state.project) {
          // proceed to connection form
          state.step = 'form';
        } else {
          // select/create project
          state.step = 'project';
        }
      } else {
        // continue to connection form
        state.step = 'form';
      }
    },
    // for Editing, extracting values from Connection type returned from API
    setConnection: (
      state: ConnectionWizardState,
      action: PayloadAction<Connection>
    ) => {
      const connection = action.payload;
      state.connectionId = connection.id;
      state.connectionType = connection.type as ConnectionType;
      state.project = {
        id: connection.project_id,
        type: connection.customer_managed_credentials_encryption
          ? 'hybrid'
          : 'cloud',
      };
      state.connectionForm = apiToForm(connection);
    },
    setConnectionType: (
      state: ConnectionWizardState,
      action: PayloadAction<ConnectionType>
    ) => {
      state.connectionType = action.payload;
    },
    setProject: (
      state: ConnectionWizardState,
      action: PayloadAction<{
        id?: string;
        displayName?: string;
        type: ProjectType;
        clusterGuid?: string;
      }>
    ) => {
      state.project = action.payload;
    },
    updateConnectionForm: (
      state: ConnectionWizardState,
      action: PayloadAction<Partial<AllConnectionForms>>
    ) => {
      // deep merge for updates to credential/config
      state.connectionForm = merge(state.connectionForm, action.payload);
      state.errors = initialState.errors;
    },
    setErrors: (
      state: ConnectionWizardState,
      action: PayloadAction<ConnectionErrors>
    ) => {
      state.errors = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: base => {
    base.addCase(ActionCreators.setDefaultForm.fulfilled, (state, action) => {
      const { connectionType, name } = action.payload;
      state.connectionForm = {
        ...initialForms[connectionType],
        name,
      };
    });
  },
});

export const {
  toNextStepFrom,
  setConnection,
  setConnectionType,
  setErrors,
  setProject,
  updateConnectionForm,
  reset,
} = connectionWizardDuck.actions;

export default connectionWizardDuck;
