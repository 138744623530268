import * as React from 'react';
import { useParams } from 'react-router';
import { Alert, Container, SvgIcon, Tabs } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import Switch from 'switch-function';
import Breadcrumbs from 'common/Breadcrumbs';
import LoaderFull from 'common/Loader/Full';
import NavTab from 'common/NavTab';
import MODEL_STATUS from 'constants/modelStatus';
import { MODELS_BY_TYPE } from 'constants/modelType';
import { Config, List, Report, Time } from 'icons/figma';
import { useModelQuery } from 'src/api';
import ModelHeader from 'src/Console/Projects/Models/Details/Header';
import ModelsNotfound from 'src/Console/Projects/Models/NotFound';
import {
  SELECTED_PROJECT_MODEL_DETAILS_ACTIVITY,
  SELECTED_PROJECT_MODEL_DETAILS_CONFIG,
  SELECTED_PROJECT_MODEL_DETAILS_DATA,
  SELECTED_PROJECT_MODEL_DETAILS_REPORT,
  SELECTED_PROJECT_MODELS,
} from 'src/routes';
import Formatters from 'utils/formatters';
import useConsoleRoute from 'utils/useConsoleRoute';
import { usePageTitle } from 'utils/usePageTitle';
import useSelectedProject from 'utils/useSelectedProject';

const SELECTED_PROJECT_MODEL_DETAILS_RUNNING_TABS = [
  SELECTED_PROJECT_MODEL_DETAILS_ACTIVITY,
  SELECTED_PROJECT_MODEL_DETAILS_CONFIG,
];

const SELECTED_PROJECT_MODEL_DETAILS_NO_REPORT_TABS = [
  SELECTED_PROJECT_MODEL_DETAILS_DATA,
  SELECTED_PROJECT_MODEL_DETAILS_ACTIVITY,
  SELECTED_PROJECT_MODEL_DETAILS_CONFIG,
];

const SELECTED_PROJECT_MODEL_DETAILS_TABS = [
  SELECTED_PROJECT_MODEL_DETAILS_DATA,
  SELECTED_PROJECT_MODEL_DETAILS_ACTIVITY,
  SELECTED_PROJECT_MODEL_DETAILS_CONFIG,
  SELECTED_PROJECT_MODEL_DETAILS_REPORT,
];

const BREADCRUMBS = [
  {
    label: 'Models',
    to: SELECTED_PROJECT_MODELS,
  },
];

const ModelDetailsLayout = ({ children }: { children: React.ReactNode }) => {
  const { selectedProject } = useSelectedProject();

  // note: sub-pages will override this, but this is a nice fallback.
  usePageTitle(
    `Model Details - ${Formatters.Project.displayName(
      selectedProject?.display_name
    )}`
  );

  const { compileLocation, consoleRoute } = useConsoleRoute({
    withRouterContext: true,
  });

  const { project: projectId, model: modelUid } = useParams<{
    project: string;
    model: string;
  }>();

  const modelQuery = useModelQuery(
    { guid: projectId, uid: modelUid },
    {
      skip: !projectId || !modelUid,
    }
  );

  const model = modelQuery.data;
  const isModelLoading = modelQuery.isUninitialized || modelQuery.isLoading;
  const modelAttributes = model && MODELS_BY_TYPE[model.model_type];
  const showReport = Boolean(
    model &&
      model?.status === MODEL_STATUS.COMPLETED &&
      modelAttributes?.hasReport
  );

  const hasReport = model && !isEmpty(model?.report);

  const TABS = React.useMemo(() => {
    if (
      model === undefined ||
      MODEL_STATUS.CREATED_PLUS_RUNNING.includes(model?.status)
    ) {
      return SELECTED_PROJECT_MODEL_DETAILS_RUNNING_TABS;
    } else if (MODEL_STATUS.FAIL_STATUSES.includes(model?.status)) {
      return SELECTED_PROJECT_MODEL_DETAILS_NO_REPORT_TABS;
    } else if (showReport && hasReport) {
      return SELECTED_PROJECT_MODEL_DETAILS_TABS;
    }
    return SELECTED_PROJECT_MODEL_DETAILS_NO_REPORT_TABS;
  }, [model, showReport, hasReport]);

  const selectedTab = TABS.indexOf(consoleRoute);

  if (isModelLoading) {
    return <LoaderFull />;
  }

  if (modelQuery.isError || !model) {
    return <ModelsNotfound />;
  }

  return (
    <Container>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ mb: 6 }}
        trailing
        items={BREADCRUMBS}
      />
      <ModelHeader model={model} sx={{ mb: 6 }} />
      {MODEL_STATUS.CREATED_PLUS_RUNNING.includes(model?.status) && (
        <Alert severity="info" sx={{ mb: 10 }}>
          Model training in progress... you can safely leave this page, start a
          new job or navigate to other projects.
        </Alert>
      )}
      <Tabs
        value={selectedTab}
        sx={{ mb: 9 }}
        aria-label="Model navigation"
        secondary="true"
        component="span"
      >
        {TABS.map(route => (
          <NavTab
            exact
            key={route.title}
            to={compileLocation(route)}
            label={route.title}
            title={route.title}
            icon={Switch(route.title, {
              [SELECTED_PROJECT_MODEL_DETAILS_DATA.title]: (
                <SvgIcon sx={{ height: 16, width: 16 }}>
                  <List />
                </SvgIcon>
              ),
              [SELECTED_PROJECT_MODEL_DETAILS_ACTIVITY.title]: (
                <SvgIcon sx={{ height: 16, width: 16 }}>
                  <Time />
                </SvgIcon>
              ),
              [SELECTED_PROJECT_MODEL_DETAILS_CONFIG.title]: (
                <SvgIcon sx={{ height: 16, width: 16 }}>
                  <Config />
                </SvgIcon>
              ),
              [SELECTED_PROJECT_MODEL_DETAILS_REPORT.title]: (
                <SvgIcon sx={{ height: 16, width: 16 }}>
                  <Report />
                </SvgIcon>
              ),
              default: null,
            })}
          />
        ))}
      </Tabs>
      {children}
    </Container>
  );
};

export default ModelDetailsLayout;
