import React from 'react';
import { Grid } from '@mui/material';
import { MODELS_BY_TYPE } from 'common/constants/modelType';
import { Model } from 'src/api/types/Model';
import { Status } from 'src/api/types/ModelRuns';
import ActionItem from './ActionItem';
import CancelTraining from './CancelTraining';
import MoreOptions from './MoreOptions';

type Props = { model: Model };

const ActionsRow = ({ children }: React.PropsWithChildren<unknown>) => (
  <Grid
    item
    container
    gap={2}
    flexGrow={1}
    justifyContent="left"
    data-testid="model-actions"
    sx={{ width: 'auto' }}
  >
    {children}
  </Grid>
);

const Actions = ({ model }: Props) => {
  const modelAttributes = MODELS_BY_TYPE[model.model_type];

  if (!modelAttributes?.hasPrimaryAction) {
    return null;
  }

  if ([Status.CREATED, Status.PENDING, Status.ACTIVE].includes(model?.status)) {
    return (
      <ActionsRow>
        <CancelTraining model={model} />
      </ActionsRow>
    );
  }

  if ([Status.CANCELLED, Status.LOST, Status.ERROR].includes(model?.status)) {
    return (
      <ActionsRow>
        <MoreOptions variant="button" model={model} />
      </ActionsRow>
    );
  }

  return (
    <ActionsRow>
      <ActionItem model={model} sx={{ width: 'auto' }} />
      <MoreOptions variant="icon" model={model} />
    </ActionsRow>
  );
};

Actions.displayName = 'Actions';

export default Actions;
