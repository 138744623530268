import { FilesAPI } from './files';
import { components as workflowConfigComponents } from './workflowConfig';

export * from './steps';

type schemas = workflowConfigComponents['schemas'];

export type Workflow = schemas['Workflow'];
export type Globals = schemas['Globals'];

export const JarvisAPI = {
  reducerPath: FilesAPI.reducerPath,
  reducer: FilesAPI.reducer,
  middleware: FilesAPI.middleware,
  util: FilesAPI.util,
};

export { FilesAPI } from './files';
export { WorkflowsAPI } from './workflows';
