import * as React from 'react';
import { Grid, GridProps, Typography } from '@mui/material';
import { reportScale } from 'common/charts/util';
import MetricLabel from 'common/Metrics/Label';
import { Score, Show } from 'icons/figma';
import { Report } from 'src/api/types/Report';

type ReportMetricsTypes = GridProps & {
  report: Report;
};

const ReportMetrics = ({ report, ...props }: ReportMetricsTypes) => {
  const qualityScore =
    report.synthetic_text_data_quality_score?.score ||
    report.synthetic_data_quality_score?.score;

  // find item in summary array with field 'data_privacy_score'
  const dataPrivacyScore = report.summary?.find(
    item => item.field === 'data_privacy_score'
  );

  const domain =
    (!qualityScore || qualityScore <= 10) &&
    (!dataPrivacyScore?.value ||
      (typeof dataPrivacyScore.value === 'number' &&
        dataPrivacyScore.value <= 10))
      ? 10
      : 100;

  return (
    <Grid container {...props}>
      {qualityScore && (
        <Grid item sx={{ mr: dataPrivacyScore ? 12 : 0 }}>
          <MetricLabel
            label="Quality Score"
            icon={<Score height={16} width={16} />}
          >
            <Typography variant="body1Medium">
              {`${reportScale(qualityScore)} / ${domain}`}
            </Typography>
          </MetricLabel>
        </Grid>
      )}
      {dataPrivacyScore?.value && (
        <Grid item>
          <MetricLabel
            label="Data Privacy Score"
            icon={<Show height={16} width={16} />}
          >
            <Typography variant="body1Medium">
              {!Array.isArray(dataPrivacyScore?.value)
                ? `${reportScale(dataPrivacyScore?.value)} / ${domain}`
                : 'N/A'}
            </Typography>
          </MetricLabel>
        </Grid>
      )}
    </Grid>
  );
};

export default ReportMetrics;
