import * as React from 'react';
import { Box, Drawer } from '@mui/material';
import { DRAWER_WIDTH } from 'common/constants/layouts';
import { useSidebarStatus } from 'common/useSidebarStatus';

const AppSidebarLayout = ({ children }: { children: React.ReactNode }) => {
  const { isSidebarOpen } = useSidebarStatus();
  return (
    <Drawer
      id="sidebar"
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
      PaperProps={{
        sx: {
          border: 'none',
        },
      }}
    >
      <Box
        component="nav"
        sx={{
          visibility: 'visible',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: DRAWER_WIDTH,
          height: '100%',
          backgroundColor: 'background.secondary',
          borderRight: 1,
          borderRightColor: 'border.default',
          ':hover #sidenav-toggle-button': { visibility: 'visible' },
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
};

export default AppSidebarLayout;
