import { useTheme } from '@mui/system';

/**
 * Used to fetch color palettes to be used for charts
 *
 * @returns String[] of colors from Red to Green
 */
export const useThemeForChartPalettes = () => {
  const { palette } = useTheme();
  return [
    palette.error[500],
    palette.warning[500],
    palette.warning[300],
    palette.success[300],
    palette.success[500],
  ];
};

const REPORT_THRESHOLD_V2 = 10;

export const reportScale = (score: number) => {
  return score && score > REPORT_THRESHOLD_V2
    ? Math.floor(score).toString()
    : Number(score ?? 0).toFixed(1);
};
