import { Components, Theme } from '@mui/material';

const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {
    notched: false,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.radii.medium,
      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
        backgroundColor: theme.palette.background.inset,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[100],
        },
      },
      '& input[readonly]': {
        backgroundColor: theme.palette.background.inset,
      },
      // notchedOutline styling
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.border.strong,
        transition: 'all 0.15s ease-out',
      },
      ':hover, &:active, &:focus': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[600],
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.strong,
        },
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[600],
        borderWidth: 1,
        boxShadow: theme.palette.action.focusBoxShadow,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
        boxShadow: theme.palette.action.errorBoxShadow,
      },
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
        {
          display: 'none',
        },
      '& input[type=number]': {
        MozAppearance: 'textfield',
      },
    }),
    multiline: {
      padding: 0,
    },
    input: ({ theme }) => ({
      padding: theme.spacing(2, 3),
    }),
    inputMultiline: ({ theme }) => ({
      padding: theme.spacing(2, 4),
    }),
    notchedOutline: {
      // For specificty reasons, see root to define pseudo states of this element
    },
  },
};

export default MuiOutlinedInput;
