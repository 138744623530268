import { parse } from 'partial-json';
import {
  FILE_TYPES,
  MAX_DOWNLOADABLE_BYTES,
  MAX_DOWNLOADABLE_PREVIEW_ROWS,
} from 'common/constants/data';
import { FileStats, getFileStats, getFileType } from 'common/utils/data';
import { type File } from 'src/api/jarvis/files';

type PreviewStreamHandler = (
  File,
  AbortController
) => undefined | ((Response) => Promise<FileStats>);

/**
 *
 * @param file
 * @param controller
 * @returns a Response handler that returns FileStats object whose results field is the parsed JSON(L) or CSV data
 *
 * Result is truncated to one network chunk more than lesser of MAX_DOWNLOADABLE_BYTES or MAX_DOWNLOADABLE_PREVIEW_ROWS.
 * JSON(L) are partially parsed to maintain valid results.
 */
export const getStreamPreviewHandler: PreviewStreamHandler = (
  file,
  controller
) => {
  if (!hasPreview(file)) {
    return undefined;
  }
  const parser = getFileResponseParser(file, controller);
  return async response => {
    const text = await parser(response);

    return getFileStats({
      file: new File([text as BlobPart], file.filename),
    });
  };
};

const getFileResponseParser =
  (file: File, controller: AbortController) => response => {
    const reader = makeResponseReader(response, controller);
    return isJSON(file)
      ? parseJSONStream(reader)
      : parseNewlineDelimitedStream(reader);
  };

const parseJSONStream = async reader => {
  let text = '';
  while (true) {
    const { value, done: eof } = await reader.read();
    if (eof) {
      break;
    }
    text += value;
    if (parse(text).length > MAX_DOWNLOADABLE_PREVIEW_ROWS) {
      reader.abort();
      break;
    }
  }
  return JSON.stringify(parse(text));
};

const parseNewlineDelimitedStream = async reader => {
  let text = '';
  while (true) {
    const { value, done: eof } = await reader.read();
    if (eof) {
      break;
    }
    text += value;
    const rowCount = text.match(/\n/g)?.length || 0;
    if (rowCount > MAX_DOWNLOADABLE_PREVIEW_ROWS) {
      reader.abort();
      break;
    }
  }
  return text;
};

const makeResponseReader = (response, controller) => {
  const reader = response.body?.getReader();
  if (!reader) {
    throw 'Failed to read response body';
  }

  const abort = () => {
    reader.cancel();
    controller?.abort();
  };

  let bytes = 0;
  const decoder = new TextDecoder('utf-8');
  const read = async () => {
    if (bytes > MAX_DOWNLOADABLE_BYTES) {
      abort();
      return { done: true };
    }
    const { value, done } = await reader.read();
    if (done) {
      return { done };
    }
    bytes += value.byteLength;
    return { value: decoder.decode(value) };
  };

  return { read, abort };
};

const hasPreview = (file: File) =>
  [FILE_TYPES.JSONL, FILE_TYPES.JSON, FILE_TYPES.CSV].includes(
    getFileType(file.filename)
  );

const isJSON = (file: File) => FILE_TYPES.JSON == getFileType(file.filename);
