import { api } from './baseApi';

export const addTagTypes = [] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      listFilesV1FilesGet: build.query<
        ListFilesV1FilesGetApiResponse,
        ListFilesV1FilesGetApiArg
      >({
        query: queryArg => ({
          url: `/v1/files`,
          params: {
            limit: queryArg.limit,
            order: queryArg.order,
          },
        }),
      }),
      uploadFileV1FilesPost: build.mutation<
        UploadFileV1FilesPostApiResponse,
        UploadFileV1FilesPostApiArg
      >({
        query: queryArg => ({
          url: `/v1/files`,
          method: 'POST',
          body: queryArg.bodyUploadFileV1FilesPost,
        }),
      }),
      deleteFileV1FilesFileIdDelete: build.mutation<
        DeleteFileV1FilesFileIdDeleteApiResponse,
        DeleteFileV1FilesFileIdDeleteApiArg
      >({
        query: queryArg => ({
          url: `/v1/files/${queryArg.fileId}`,
          method: 'DELETE',
        }),
      }),
      getFileV1FilesFileIdGet: build.query<
        GetFileV1FilesFileIdGetApiResponse,
        GetFileV1FilesFileIdGetApiArg
      >({
        query: queryArg => ({ url: `/v1/files/${queryArg.fileId}` }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as FilesAPI };
export type ListFilesV1FilesGetApiResponse =
  /** status 200 Successful Response */ Record<string, number>;
export type ListFilesV1FilesGetApiArg = {
  limit?: number | null;
  order?: string | null;
};
export type UploadFileV1FilesPostApiResponse =
  /** status 200 Successful Response */ File;
export type UploadFileV1FilesPostApiArg = {
  bodyUploadFileV1FilesPost: BodyUploadFileV1FilesPost;
};
export type DeleteFileV1FilesFileIdDeleteApiResponse =
  /** status 200 Successful Response */ FileDeleteResponse;
export type DeleteFileV1FilesFileIdDeleteApiArg = {
  fileId: string;
};
export type GetFileV1FilesFileIdGetApiResponse =
  /** status 200 Successful Response */ File;
export type GetFileV1FilesFileIdGetApiArg = {
  fileId: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type File = {
  bytes: number;
  created_at: number;
  file_id: string;
  filename: string;
  object: string;
  purpose: string;
};
export type BodyUploadFileV1FilesPost = {
  file: Blob;
  purpose: string;
};
export type FileDeleteResponse = {
  deleted: boolean;
  file_id: string;
  object: string;
};
export const {
  useListFilesV1FilesGetQuery,
  useLazyListFilesV1FilesGetQuery,
  useUploadFileV1FilesPostMutation,
  useDeleteFileV1FilesFileIdDeleteMutation,
  useGetFileV1FilesFileIdGetQuery,
  useLazyGetFileV1FilesFileIdGetQuery,
} = injectedRtkApi;
