import * as React from 'react';
import { matchPath } from 'react-router';
import { Tabs } from '@mui/material';
import { useConsoleGA } from 'common/featureFlags';
import NavTab from 'common/NavTab';
import useSelectedProject from 'common/utils/useSelectedProject';
import {
  isRoute,
  Route,
  SELECTED_PROJECT_MODELS,
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT,
} from 'src/routes';
import useConsoleRoute from 'utils/useConsoleRoute';

const SELECTED_PROJECT_TABS =
  SELECTED_PROJECT_ROUTES_WITH_PROJECT_LAYOUT.filter<Route>(isRoute).filter(
    route => route.isTab
  );

/** needed for Console GA (Q2 2025) */
const useHideModelsTabForConsoleGA = () => {
  const { value: isGAEnabled = false } = useConsoleGA();
  const { selectedProject: { model_count = 0 } = {} } = useSelectedProject({
    expand: 'models',
  });

  return isGAEnabled && model_count < 1;
};

const ProjectTabs = () => {
  const { consoleRoute, compilePath, location } = useConsoleRoute({
    withRouterContext: true,
  });
  const { childComponent, ...serializableConsoleRoute } = consoleRoute;
  const hideModelsTab = useHideModelsTabForConsoleGA();

  const tabs = React.useMemo(
    () =>
      hideModelsTab
        ? SELECTED_PROJECT_TABS.filter(tab => tab !== SELECTED_PROJECT_MODELS)
        : SELECTED_PROJECT_TABS,
    [hideModelsTab]
  );

  const currentTabIndex = tabs.findIndex(
    ({ path }) => matchPath(window.location.pathname, path) !== null
  );

  return (
    <Tabs value={currentTabIndex >= 0 ? currentTabIndex : false}>
      {tabs.map((tabRoute, index) => (
        <NavTab
          key={`selected-project-navigation-tab-${index}`}
          label={tabRoute.title}
          to={{
            ...location,
            pathname: compilePath(tabRoute),
            state: {
              from: {
                consoleRoute: serializableConsoleRoute,
                ...location,
              },
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default ProjectTabs;
